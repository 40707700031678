
import { MODAL_MAIN } from '~/store/modal/constants';

export default {
    props: {
        event: {
            type: Object,
            required: true
        },

        appearance: {
            type: String,
            default: 'box',
            validator(value) {
                return ['box', 'list'].includes(value);
            }
        }
    },

    computed: {
        label() {
            // @TODO: add isNew functionality
            if (this.event.isNew) {
                return 'Nieuw';
            }

            if (this.event.programLine) {
                return this.event.programLine.name;
            }

            return null;
        },

        isReserved() {
            return this.$store.getters['user/hasReservation'](this.event.id);
        },

        isSaved() {
            return this.$store.getters['user/hasFavorite'](this.event.id);
        },

        featuredImage() {
            return this.event?.featuredImage;
        },

        imageSrc() {
            if (!this.featuredImage) {
                return;
            }

            if (this.appearance === 'list') {
                return this.featuredImage.url_list_md;
            }

            return this.featuredImage.url_box_md;
        },

        imageSrcset() {
            if (!this.featuredImage) {
                return;
            }

            if (this.appearance === 'list') {
                return `
                    ${this.featuredImage.url_list_md} 80w,
                    ${this.featuredImage.url_list_lg} 2x
                `;
            }

            return `
                ${this.featuredImage.url_box_md} 270w,
                ${this.featuredImage.url_box_lg} 2x
            `;
        },

        imageWebpSrcset() {
            if (!this.featuredImage) {
                return;
            }

            if (
                this.appearance === 'list' &&
                this.featuredImage.url_list_md_webp &&
                this.featuredImage.url_list_lg_webp
            ) {
                return `
                    ${this.featuredImage.url_list_md_webp} 80w,
                    ${this.featuredImage.url_list_lg_webp} 2x
                `;
            }

            if (
                this.featuredImage.url_list_md_webp &&
                this.featuredImage.url_list_lg_webp
            ) {
                return `
                    ${this.featuredImage.url_box_md_webp} 270w,
                    ${this.featuredImage.url_box_lg_webp} 2x
                `;
            }

            return undefined;
        }
    },

    methods: {
        showEventDetail(event) {
            event.preventDefault();
            event.stopPropagation();

            if (this.$store.state.events.activeEventId === null) {
                this.$store.dispatch('events/setStartPath', this.$route.path);
            }

            this.$store.dispatch('modal/open', { name: MODAL_MAIN });
            this.$store.dispatch('events/activate', { id: this.event.id });

            window.history.pushState('', '', `/programma/${this.event.uri}`);
        }
    }
};
