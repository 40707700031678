export default {
    close({ commit }, payload) {
        commit('deactivateModal', payload.name);
    },

    /**
     * Open specific modal
     *
     * The payload can either be a string matching a modal's name,
     * or an object containing a 'name' property. When using
     * the object any data you add is accessible using the
     * 'modal/data' getter in your component.
     *
     * @param   {String|Object}  payload  String or object containing modal name.
     */
    open({ commit }, payload) {
        // If no modal name is present, reset it.
        if (!payload || !payload.name) {
            commit('deactivateAllModals');
        }

        // If the payload is a string, just interpret that as the modal's name.
        if (typeof payload === 'string') {
            return commit('activateModal', payload);
        }

        // If it does have a name, open it. And set the payload object
        // to the current modal data, for use in the component iself.
        if (payload.name) {
            commit('activateModal', {
                modalName: payload.name,
                data: payload.data
            });
        }
    }
};
