
export default {
    props: {
        collection: {
            type: Object,
            required: true
        },

        isFeatured: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        featuredImages() {
            return this.collection.collectionFeaturedImages;
        },

        imgLayerOne() {
            return this.collection.collectionFeaturedImages.length > 0
                ? {
                    srcset: this.imgLayerSrcset(0),
                    webpSrcset: this.imgLayerWebpSrcset(0),
                    ...this.featuredImages[0]
                }
                : null;
        },

        imgLayerTwo() {
            return this.featuredImages.length > 1
                ? {
                    srcset: this.imgLayerSrcset(1),
                    webpSrcset: this.imgLayerWebpSrcset(1),
                    ...this.featuredImages[1]
                }
                : null;
        },

        imgLayerThree() {
            return this.featuredImages.length > 2
                ? {
                    srcset: this.imgLayerSrcset(2),
                    webpSrcset: this.imgLayerWebpSrcset(2),
                    ...this.featuredImages[2]
                }
                : null;
        },

        curator() {
            return this.collection.collectionCurator[0] ?? null;
        },

        curatorFeaturedImage() {
            if (!this.curator) {
                return;
            }

            if (this.isFeatured) {
                return this.curator.curatorImageUrlMd ?? null;
            }

            return this.curator.curatorImageUrlSm ?? null;
        },

        curatorFeaturedSrcset() {
            if (!this.curator) {
                return;
            }

            if (this.isFeatured) {
                return `
                    ${this.curator.curatorImageUrlMd} 1x,
                    ${this.curator.curatorImageUrlMd_2x} 2x
                `;
            }

            return `
                ${this.curator.curatorImageUrlSm} 1x,
                ${this.curator.curatorImageUrlSm_2x} 2x
            `;
        },

        curatorFeaturedWebpSrcset() {
            if (!this.curator) {
                return;
            }

            if (this.isFeatured) {
                return `
                    ${this.curator.curatorImageUrlMdWebp} 1x,
                    ${this.curator.curatorImageUrlMdWebp_2x} 2x
                `;
            }

            return `
                ${this.curator.curatorImageUrlSmWebp} 1x,
                ${this.curator.curatorImageUrlSmWebp_2x} 2x
            `;
        }
    },

    methods: {
        imgLayerSrcset(index) {
            if (this.isFeatured) {
                return `
                    ${this.featuredImages[index].url_hero_xs} 576w,
                    ${this.featuredImages[index].url_hero_sm} 768w
                `.trim();
            }
            return `
                ${this.featuredImages[index].w320} 320w,
                ${this.featuredImages[index].url_hero_xs} 576w
            `.trim();
        },

        imgLayerWebpSrcset(index) {
            if (this.isFeatured) {
                return `
                    ${this.featuredImages[index].webp_url_hero_xs} 576w,
                    ${this.featuredImages[index].webp_url_hero_sm} 768w
                `.trim();
            }
            return `
                ${this.featuredImages[index].webp_w320} 320w,
                ${this.featuredImages[index].webp_url_hero_xs} 576w
            `.trim();
        },
    }
};
