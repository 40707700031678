
import {
    parseISO,
    format
} from 'date-fns';

export default {
    props: {
        event: {
            type: Object,
            required: true
        }
    },

    computed: {
        eventHasCity() {
            return this.event?.venue?.city;
        },

        relatedCityEventBundle() {
            if (!this.event.venue || !this.event.venue.city || !this.event.venue.city.id) {
                return false;
            }

            const startDateObj = parseISO(this.event.start_date);
            const formattedDate = format(startDateObj, 'dd-MM-yyyy');

            return [{
                startDate: formattedDate,
                city: [
                    {
                        laravelModelId: this.event.venue.city.id
                    }
                ],
                skipEvent: this.event.id
            }];
        },

        relatedDisciplineEventBundle() {
            if (!this.event.venue || !this.event.venue.city || !this.event.venue.city.id || !this.event.disciplines.length) {
                return false;
            }

            return [{
                discipline: [
                    {
                        laravelModelId: this.event.disciplines[0].id
                    }
                ],
                city: [
                    {
                        laravelModelId: this.event.venue.city.id
                    }
                ],
                skipEvent: this.event.id
            }];
        }
    }
};
