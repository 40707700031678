
export default {
    props: {
        image: {
            type: Object,
            default: null
        },
        content: {
            type: String,
            default: null
        },
        author: {
            type: String,
            default: null
        },
        title: {
            type: String,
            default: null
        }
    },

    computed: {
        imageSrcSet() {
            if (!this.image) {
                return;
            }

            return `
                ${this.image.url_sm} 44w,
                ${this.image.url_md} 64w,
                ${this.image.url_lg} 2x
            `;
        },

        imageWebpSrcSet() {
            if (!this.image) {
                return;
            }

            return `
                ${this.image.url_sm_webp} 44w,
                ${this.image.url_md_webp} 64w,
                ${this.image.url_lg_webp} 2x
            `;
        }
    }
};
