import { render, staticRenderFns } from "./EventMeta.vue?vue&type=template&id=31e9952d"
import script from "./EventMeta.vue?vue&type=script&lang=js"
export * from "./EventMeta.vue?vue&type=script&lang=js"
import style0 from "./EventMeta.less?vue&type=style&index=0&prod&lang=less&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {EventDate: require('/builds/WeArePublic/wap-app/components/Event/Date/EventDate.vue').default,EventArtists: require('/builds/WeArePublic/wap-app/components/Event/Artists/EventArtists.vue').default,EventVenue: require('/builds/WeArePublic/wap-app/components/Event/Venue/EventVenue.vue').default})
