
import DnModal from '@digitalnatives/modal';
import getGlobalsQuery from '~/graphql/queries/Globals.graphql';
import { SIGNUP_URL } from '~/constants/membership';
import { MODAL_LOGIN } from '~/store/modal/constants';
import getCraftUri from '~/util/urls';
import Navigation from '~/mixins/Navigation';
import QueryMixin from '~/mixins/query.js';

export default {
    components: {
        DnModal
    },

    mixins: [
        Navigation,
        QueryMixin
    ],

    data() {
        return {
            activeNavKey: null,
            visible: false,
            mainNavigation: null,
            footerNavigation: null,
            SIGNUP_URL
        };
    },

    async fetch() {
        const { data } = await this.craftQuery(getGlobalsQuery);

        if (!data) {
            return;
        }

        if (data.mainNavigation && data.mainNavigation.links) {
            this.mainNavigation = data?.mainNavigation.links;
        }

        if (data.footer && data.footer.links) {
            this.footerNavigation = data?.footer.links;
            this.$store.dispatch('navigation/setFooter', data.footer);
        }

        if (data.fairUsePolicy && data.fairUsePolicy.entry && data.fairUsePolicy.entry.length > 0) {
            this.$store.dispatch('events/setFairUsePolicy', data.fairUsePolicy.entry[0].uri);
        }

        if (data.membership) {
            this.$store.dispatch('navigation/setBecomeMemberCta', data.membership);
        }
    },

    computed: {
        uri() {
            return getCraftUri(this, this.$route.path);
        },

        becomeMemberText() {
            return this.$store.getters['navigation/becomeMemberCtaDefault'];
        }
    },

    methods: {
        openLogin() {
            this.$store.dispatch('modal/open', { name: MODAL_LOGIN });
            this.$menu.close();
        }
    }
};
