
import { SIGNUP_URL, FRIEND_URL } from '~/constants/membership';
import { MODAL_MAIN } from '~/store/modal/constants';

export default {
    props: {
        eventId: {
            type: [String, Number],
            default: null,
            required: true
        }
    },

    data() {
        return {
            SIGNUP_URL,
            FRIEND_URL
        };
    },

    computed: {
        becomeMemberText() {
            return this.$store.getters['navigation/becomeMemberCtaDefault'];
        }
    },

    methods: {
        getDescription(text) {
            return `<h2>Over dit programma</h2>${text}`;
        },

        getTrailerCaption(event) {
            if (!event) {
                return '';
            }

            let caption = event.trailerCaption || '';

            if (event.trailerCredits && event.trailerCaption) {
                caption += ` - ${event.trailerCredits}`;
            }

            if (event.trailerCredits && !event.trailerCaption) {
                caption = event.trailerCredits;
            }

            return caption;
        },

        closeModal() {
            this.$store.dispatch('modal/close', { name: MODAL_MAIN });
        }
    }
};
