import { render, staticRenderFns } from "./Event.vue?vue&type=template&id=4c90bcd7"
import script from "./Event.vue?vue&type=script&lang=js"
export * from "./Event.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {StateSwitch: require('/builds/WeArePublic/wap-app/components/StateSwitch/StateSwitch.vue').default})
