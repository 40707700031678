export default {
    deactivate({ commit }) {
        commit('deactivateEvent');
        commit('setActiveEventData', null);
    },

    activate({ commit }, payload) {
        if (typeof payload === 'string') {
            return commit('activateEvent', payload);
        }

        if (payload.id) {
            commit('activateEvent', payload.id);
        }
    },

    setActiveEventData({ commit }, payload) {
        commit('setActiveEventData', payload);
    },

    setStartPath({ commit }, payload) {
        commit('setStartPath', payload);
    },

    updateMyListHome({ commit }) {
        commit('updateMyListHome');
    }
};
