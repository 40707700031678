
export default {
    props: {
        venue: {
            type: Object,
            default: null
        }
    },

    computed: {
        formattedLabel() {
            if (!this.venue) {
                return;
            }

            if (this.venue.name && this.venue.city) {
                return `${this.venue.name}, ${this.venue.city.name}`;
            }

            return this.venue.name;
        }
    }
};
