
export default {
    props: {
        title: {
            type: String,
            required: true
        },
        message: {
            type: String,
            required: true
        },
        status: {
            type: String,
            required: true
        },
        interval: {
            type: Number,
            default: 6000
        },
        id: {
            type: [String, Number],
            default: null,
            required: true
        }
    },

    created() {
        this.timeout = setTimeout(() => this.removeToast(), this.interval);
    },

    methods: {
        removeToast() {
            this.$emit('removeToast', this.id);
        }
    }
};
