
export default {
    data() {
        return {
            img: null,
            isLoading: true
        };
    },

    computed: {
        barcode() {
            return this.$auth.user.barcode;
        }
    },

    mounted() {
        this.$axios.get('v1.0/users/me/barcode').then(({ data }) => {
            this.img = data;
            this.isLoading = false;
        });
    }
};
