
export default {
    props: {
        events: {
            type: Array,
            default: null
        },

        cardSize: {
            type: String,
            default: 'default',
            validator(value) {
                return ['default', 'large'].includes(value);
            }
        }
    }
};
