
import { ValidationProvider } from 'vee-validate';

import {
    STATUS,
    PASSWORD_RESET_INVALID_CODE,
    PASSWORD_RESET_TOO_MANY_ATTEMPTS,
} from '~/constants/errorMessages';

export default {
    components: { ValidationProvider },

    data() {
        return {
            formData: {
                code: null
            },
            errorMessage: null
        };
    },

    computed: {
        userEmail() {
            return this.$store.state.emailConfirm?.email;
        }
    },

    methods: {
        async submitCode() {
            this.errorMessage = null;

            await this.$axios({
                method: 'post',
                url: '/v2.0/user/email-confirmation/check',
                data: {
                    email: this.userEmail,
                    token: this.formData.code
                }
            })
                .catch((error) => {
                    if (error?.response?.status === 429) {
                        this.errorMessage = PASSWORD_RESET_TOO_MANY_ATTEMPTS;
                    } else if (error?.response?.status === 400) {
                        this.errorMessage = PASSWORD_RESET_INVALID_CODE;
                    } else {
                        this.errorMessage = STATUS['500'];
                    }
                })
                .then((res) => {
                    if (res?.data?.user) {
                        this.$store.dispatch('emailConfirm/setUser', res.data.user);
                    }

                    if (res?.data?.token) {
                        this.$store.dispatch('emailConfirm/setToken', res.data.token);
                    }

                    if (this.errorMessage === null) {
                        this.$emit('nextStep');
                    }
                });
        }
    }
};
