
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters({
            activeModals: 'modal/activeModals',
            modalData: 'modal/data'
        }),

        isUserCardSheetOpen() {
            return this.activeModals.userCardSheet === true;
        },
    }
};
