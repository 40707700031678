
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters({
            previousRoute: 'search/previousRoute'
        }),

        isSearchPage() {
            return this.$route.name === 'zoeken';
        },

        link() {
            if (this.isSearchPage) {
                return this.previousRoute ?? '/';
            }
            return '/zoeken';
        },

        icon() {
            if (this.isSearchPage) {
                return 'arrow-left';
            }
            return 'search';
        }
    }
};
