
export default {
    props: {
        event: {
            type: Object,
            default: null
        }
    },

    computed: {
        title() {
            if (!this.event) {
                return;
            }

            return this.event.title;
        },

        artists() {
            if (!this.event || !this.event.artists || !this.event.artists.length) {
                return;
            }

            return this.event.artists;
        },
    }
};
