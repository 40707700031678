import { extend, setInteractionMode } from 'vee-validate';
import { required, email, regex, min } from 'vee-validate/dist/rules.umd.js';
const ibanTools = require('ibantools');

// Set interaction mode
setInteractionMode('eager');

extend('regex', {
    ...regex,
    message: 'Ongeldige waarde'
});

extend('email', {
    ...email,
    message: 'Voer een geldig e-mailadres in'
});

extend('required', {
    ...required,
    message: 'Dit veld is verplicht'
});

extend('min', {
    ...min,
    params: ['length'],
    message: 'Dit veld moet uit minimaal {length} tekens bestaan',
});

extend('iban', {
    message: 'Dit is geen geldig IBAN nummer',
    validate: value => ibanTools.isValidIBAN(value.trim().toUpperCase())
});

extend('zipCode', {
    message: 'Dit is geen geldige postcode',
    validate: value => value.match(/[0-9]{4} ?[A-Z]{2}/ig)
});
