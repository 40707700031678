
import { ValidationProvider } from 'vee-validate';

import {
    STATUS,
    PASSWORD_RESET_TOO_MANY_ATTEMPTS,
    PASSWORD_MISMATCH
} from '~/constants/errorMessages';

export default {
    components: { ValidationProvider },

    data() {
        return {
            formData: {
                password: null,
                passwordConfirmation: null
            },
            errorMessage: null
        };
    },

    computed: {
        user() {
            return this.$store.state.emailConfirm?.user;
        },

        token() {
            return this.$store.state.emailConfirm?.token;
        }
    },

    methods: {
        async checkPassword() {
            if (this.formData.password !== this.formData.passwordConfirmation) {
                this.errorMessage = PASSWORD_MISMATCH;
                return;
            }

            await this.$axios({
                method: 'post',
                url: '/v2.0/user/password-reset',
                data: {
                    password: this.formData.password,
                    password_confirmation: this.formData.passwordConfirmation,
                    token: this.token,
                    id: this.user.id
                }
            })
                .catch((error) => {
                    if (error?.response?.status === 429) {
                        this.errorMessage = PASSWORD_RESET_TOO_MANY_ATTEMPTS;
                    } else {
                        this.errorMessage = STATUS['500'];
                    }
                });

            this.$emit('submitPassword');
        }
    }
};
