
export const LINK = 'link';
export const NUXT_LINK = 'nuxt_link';

export default {
    props: {
        type: {
            type: String,
            validator: value => [LINK, NUXT_LINK].includes(value),
            default: NUXT_LINK
        },
        url: {
            type: [String, Object],
            required: true
        },
        blank: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            LINK,
            NUXT_LINK
        };
    }
};
