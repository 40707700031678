'use strict';
import https from 'https';

export default function({ $axios, $config }, inject) {
    // Create a separate Axios instance for Craft CMS requests,
    // as they require different headers / bearer tokens than the
    // Laravel API.
    const craftAxios = $axios.create(
        {
            withCredentials: true,
            headers: {
                Authorization: 'Bearer ' + $config.apiToken
            }
        }
    );

    // Create a separate Axios instance for requests to the laravel REST / content API.
    // Nuxt-auth 'hijacks' the main instance, which can lead to SSR errors.
    const laravelAxios = $axios.create();

    if ($config.craftApiUrl) {
        craftAxios.setBaseURL($config.craftApiUrl);
    }

    // The Laravel API expects the `x-api-key` header.
    if ($config.laravelApiKey) {
        $axios.setHeader('x-api-key', $config.laravelApiKey);
        laravelAxios.setHeader('x-api-key', $config.laravelApiKey);
    }

    if ($config.laravelBaseURL) {
        $axios.setBaseURL($config.laravelBaseURL);
        laravelAxios.setBaseURL($config.laravelBaseURL);
    }

    // There may be an x-api-key header in Axios' default headers, remove it for Craft.
    if ('x-api-key' in craftAxios.defaults.headers) {
        delete craftAxios.defaults.headers['x-api-key'];
    }

    // Ignore https errors on local enviroments.
    if (process.env.APP_ENV === 'development') {
        const agent = new https.Agent({
            rejectUnauthorized: false
        });

        laravelAxios.onRequest((config) => {
            config.httpsAgent = agent;
        });

        craftAxios.onRequest((config) => {
            config.httpsAgent = agent;
        });

        $axios.onRequest((config) => {
            config.httpsAgent = agent;
        });
    }

    // In Vue components you may use this.$craftApi instead of this.$axios.
    inject('craftApi', craftAxios);
    inject('laravelApi', laravelAxios);
}
