
export default {
    props: {
        events: {
            type: Array,
            default() {
                return [];
            }
        }
    },
};
