
import DnVideoEmbed from '@digitalnatives/video-embed';

export default {
    components: { DnVideoEmbed },

    props: {
        title: {
            type: String,
            default: null
        },
        url: {
            type: String,
            default: null
        },
        caption: {
            type: String,
            default: null
        }
    }
};
