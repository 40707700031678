import { render, staticRenderFns } from "./Hero.vue?vue&type=template&id=97f88478"
import script from "./Hero.vue?vue&type=script&lang=js"
export * from "./Hero.vue?vue&type=script&lang=js"
import style0 from "./Hero.less?vue&type=style&index=0&prod&lang=less&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseImage: require('/builds/WeArePublic/wap-app/components/Base/Image/BaseImage.vue').default,EventDate: require('/builds/WeArePublic/wap-app/components/Event/Date/EventDate.vue').default})
