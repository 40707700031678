
import FlexibleSectionMixin from '~/mixins/flexible-section';

export default {
    mixins: [
        FlexibleSectionMixin,
    ],

    props: {
        sectionTitle: {
            type: String,
            default: null
        },

        collectionType: {
            type: Array,
            default: null
        },

        fetchOnServer: {
            type: Boolean,
            default: true
        }
    },

    computed: {
        collectionTypeId() {
            if (!this.collectionType || this.collectionType.length <= 0) {
                return;
            }

            return this.collectionType[0].id;
        }
    }
};
