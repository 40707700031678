export default function getCraftUri(vm, path) {
    if (path.startsWith('/')) {
        // trim the leading slash
        path = path.substring(1);
    }

    // vm.$i18n.locales.forEach(({ code }) => {
    //     if (vm.$nuxt.context.route.path.startsWith(`/${code}`) && path.startsWith(`${code}/`)) {
    //         // Trim the laguage prefix
    //         path = path.substring(3);
    //     }
    // });

    if (path.endsWith('/')) {
        // trim the trailing slash
        path = path.substring(0, path.length - 1);
    }

    return path;
}
