
import FlexibleSectionMixin from '~/mixins/flexible-section';

export default {
    mixins: [FlexibleSectionMixin],

    props: {
        callToAction: {
            type: Object,
            default: null
        },

        secondaryCallToAction: {
            type: Object,
            default: null
        },

        sectionTitle: {
            type: String,
            default: null
        },

        richText: {
            type: String,
            default: null
        },

        image: {
            type: Array,
            default: null
        }
    }
};
