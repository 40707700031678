
import Navigation from '~/mixins/Navigation';
import FlexibleSectionMixin from '~/mixins/flexible-section';

export default {
    mixins: [
        FlexibleSectionMixin,
        Navigation
    ],

    props: {
        eventBundle: {
            type: Array,
            default() {
                return [];
            }
        },

        title: {
            type: String,
            default: null
        },

        cardSize: {
            type: String,
            default: null
        },

        callToAction: {
            type: Object,
            default: null
        },

        eventBundleEntry: {
            type: Array,
            default() {
                return [];
            }
        },

        eventBlockPresentationType: {
            type: String,
            default: 'slider'
        },

        fetchOnServer: {
            type: Boolean,
            default: true
        }
    },

    computed: {
        presentation() {
            return this.eventBlockPresentationType;
        },

        sectionWidth() {
            if (this.eventBlockPresentationType === 'slider') {
                return 'bleed';
            }

            return 'page';
        }
    }
};
