
export default {
    props: {
        statusCode: {
            type: Number,
            default: 0
        }
    },

    computed: {
        heroImage() {
            return {
                url: 'img/placeholders/placeholder.jpg',
                url_hero_lg: 'img/placeholders/placeholder@5x.jpg',
                url_hero_md: 'img/placeholders/placeholder@4x.jpg',
                url_hero_sm: 'img/placeholders/placeholder@3x.jpg',
                url_hero_tiny: 'img/placeholders/placeholder.jpg',
                url_hero_xs: 'img/placeholders/placeholder.jpg',
                width: 0,
                height: 0
            };
        },

        entry() {
            return {};
        },

        title() {
            if (this.statusCode === 404) {
                return 'Pagina niet gevonden';
            } else if (this.statusCode === 500) {
                return 'Er ging iets mis';
            }
            return '';
        },

        description() {
            if (this.statusCode === 404) {
                return '<p>Deze pagina bestaat niet meer.</p><p>Op zoek naar een programma? Bekijk het <a href="/">programmaoverzicht</a>.</p>';
            } else if (this.statusCode === 500) {
                return '<p>Er ging iets mis bij het laden van deze pagina. We doen ons best dit zo snel mogelijk te verhelpen.</p><p>Op zoek naar een programma? Bekijk het <a href="/">programmaoverzicht</a>.</p>';
            }
            return '';
        }
    }
};
