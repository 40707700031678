
import favicons from '~/mixins/favicons';

export default {
    mixins: [favicons],

    props: {
        error: {
            type: Object,
            required: false,
            default: null
        }
    }
};
