import { render, staticRenderFns } from "./EventFilters.vue?vue&type=template&id=5f19e949"
import script from "./EventFilters.vue?vue&type=script&lang=js"
export * from "./EventFilters.vue?vue&type=script&lang=js"
import style0 from "./EventFilters.less?vue&type=style&index=0&prod&lang=less&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FilterDateSelect: require('/builds/WeArePublic/wap-app/components/Filter/DateSelect/FilterDateSelect.vue').default,FilterCitySelect: require('/builds/WeArePublic/wap-app/components/Filter/CitySelect/FilterCitySelect.vue').default})
