
export default {
    props: {
        event: {
            type: Object,
            default: null
        }
    },

    computed: {
        programLine() {
            if (!this.event.programLine) {
                return;
            }
            return this.event.programLine.name;
        },

        isNotReservable() {
            return (this.event.isFullyBooked && !this.event.doorsaleEnabled) || this.event.isSoldout;
        },

        isNotActive() {
            return !this.event.isActive;
        }
    }
};
