
import FlexibleSectionMixin from '~/mixins/flexible-section';

export default {
    mixins: [
        FlexibleSectionMixin
    ],

    props: {
        title: {
            type: String,
            default: null
        },
        videoUrl: {
            type: String,
            default: null
        },
        caption: {
            type: String,
            default: null
        }
    }
};
