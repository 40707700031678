
export default {
    props: {
        events: {
            type: Array,
            default() {
                return [];
            }
        },

        totalResults: {
            type: Number,
            default: 0
        },

        currentPageNumber: {
            type: Number,
            default: 1
        },

        lastPageNumber: {
            type: Number,
            default: 1
        }
    },
};
