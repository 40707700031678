export default {
    reservations: (state, commit, rootState) => rootState?.auth?.user?.reservations || [],
    hasReservation: (state, commit, rootState) => (id) => {
        const reservations = rootState?.auth?.user?.reservations || [];
        return reservations.includes(id);
    },

    isInWaitingList: (state, commit, rootState) => (id) => {
        const waitingList = rootState?.auth?.user?.waitingLists || [];
        return waitingList.includes(id);
    },

    hasFavorite: (state, commit, rootState) => (id) => {
        const favorites = rootState?.auth?.user?.savedProgrammes || [];
        return favorites.includes(id);
    }
};
