'use strict';

import { LINK, NUXT_LINK } from '~/components/Nav/Link';
import { HOME_URI } from '~/constants/page-uri';

export default {
    methods: {
        mapType(type) {
            switch (type) {
            case 'email':
            case 'tel':
            case 'url':
                return LINK;
            case 'entry':
            case 'category':
                return NUXT_LINK;
            }
        },

        mapURI(link) {
            const type = this.mapType(link.type);

            if (type === NUXT_LINK) {
                return this.fixHomeUri(link?.element?.uri);
            }

            return link.url;
        },

        isExternal(link) {
            return link.target === '_blank';
        },

        fixHomeUri(uri) {
            if (uri === HOME_URI) {
                return {
                    query: this.$route.query.length > 0 ? this.$route.query : null,
                    path: '/'
                };
            }

            return {
                query: this.$route.query,
                path: `/${uri}`
            };
        },
    }
};
