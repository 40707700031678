
import { STATUS, EVENT_FULL_CAPACITY } from '~/constants/errorMessages';

export default {
    props: {
        eventId: {
            type: [String, Number],
            default: null
        },

        eventTitle: {
            type: String,
            default: null
        }
    },

    data() {
        return {
            isSubmitting: false,
            errorMessage: null
        };
    },

    computed: {
        hasReservation() {
            return this.$store.getters['user/hasReservation'](this.eventId);
        },

        makeReservationConfirmText() {
            if (this.eventTitle) {
                return `Weet je zeker dat je een plek wilt reserveren voor ${this.eventTitle}? Reserveer alleen als je ook echt kan gaan. Anders kan iemand anders in jouw plaats.`;
            }

            return 'Weet je zeker dat je een plek wilt reserveren? Reserveer alleen als je ook echt kan gaan. Anders kan iemand anders in jouw plaats.';
        },

        cancelReservationConfirmText() {
            if (this.eventTitle) {
                return `Weet je zeker dat je je reservering voor ${this.eventTitle} wilt annuleren?`;
            }

            return 'Weet je zeker dat je je reservering voor dit programma wilt annuleren?';
        }
    },

    methods: {
        toggleReservation() {
            if (this.hasReservation) {
                return this.cancelReservation();
            }

            return this.makeReservation();
        },

        async makeReservation() {
            const confirmed = confirm(this.makeReservationConfirmText);

            if (!confirmed) {
                return;
            }

            this.isSubmitting = true;

            await this.$axios({
                method: 'post',
                url: `/v2.0/user/me/reservations/${this.eventId}`
            }).catch((error) => {
                if (error?.response?.status === 422) {
                    this.errorMessage = EVENT_FULL_CAPACITY;
                } else {
                    this.errorMessage = STATUS['500'];
                }
                this.isSubmitting = false;
            });

            // Refresh the user, so we have the most recent reservations.
            this.$store.dispatch('events/updateMyListHome');
            this.$auth.fetchUser();
            this.isSubmitting = false;
        },

        async cancelReservation() {
            const confirmed = confirm(this.cancelReservationConfirmText);

            if (!confirmed) {
                return;
            }

            this.isSubmitting = true;

            await this.$axios({
                method: 'delete',
                url: `/v2.0/user/me/reservations/${this.eventId}`
            }).catch(() => {
                this.errorMessage = STATUS['500'];
                this.isSubmitting = false;
            });

            // Refresh the user, so we have the most recent reservations.
            this.$auth.fetchUser();
            this.isSubmitting = false;

            this.$store.commit('toasts/addMessage', {
                title: 'Je hebt je reservering geannuleerd',
                message: 'Heb je je tóch bedacht? Reserveer dan opnieuw een plekje.',
                status: 'success'
            });
            this.$store.dispatch('events/updateMyListHome');
        }
    }
};
