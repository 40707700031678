
import { mapGetters } from 'vuex';

const CITY_FILTER = 'city';
const REGION_FILTER = 'region';
const LOCAL_STORAGE_KEY = `filter-${CITY_FILTER}-${REGION_FILTER}`;

export default {
    data() {
        const selectedValue = () => {
            if (CITY_FILTER in this.$route.query) {
                return `${CITY_FILTER}-${this.$route.query.city}`;
            }

            if (REGION_FILTER in this.$route.query) {
                return `${REGION_FILTER}-${this.$route.query.region}`;
            }

            return null;
        };

        return {
            selectedValue: selectedValue(),
            CITY_FILTER,
            REGION_FILTER
        };
    },

    async fetch() {
        await this.$store.dispatch('eventFilters/fetchFilterOptions');
    },

    computed: {
        ...mapGetters({
            showEventFilters: 'eventFilters/filtersVisible'
        }),

        cityOptions() {
            return this.$store.getters['eventFilters/cityOptions'];
        },

        regionOptions() {
            return this.$store.getters['eventFilters/regionOptions'];
        },

        mainProgramEntry() {
            return this.$store.getters['eventFilters/mainProgramEntry'];
        }
    },

    watch: {
        '$route.query.city'(value) {
            if (value) {
                this.selectedValue = `${CITY_FILTER}-${value}`;
            } else if (!this.$route.query.region) {
                this.selectedValue = null;
            }
        },
        '$route.query.region'(value) {
            if (value) {
                this.selectedValue = `${REGION_FILTER}-${value}`;
            } else if (!this.$route.query.city) {
                this.selectedValue = null;
            }
        }
    },

    beforeMount() {
        if (
            !this.selectedValue &&
            this.showEventFilters &&
            localStorage.getItem(LOCAL_STORAGE_KEY)
        ) {
            const storageFilter = localStorage.getItem(LOCAL_STORAGE_KEY);

            this.selectedValue = storageFilter;

            this.updateFilterState();
        }
    },

    methods: {
        updateFilterState() {
            const route = {
                query: { ...this.$route.query }
            };

            if (!this.selectedValue) {
                delete route.query.city;
                delete route.query.region;

                localStorage.removeItem(LOCAL_STORAGE_KEY);
            } else {
                const selected = this.selectedValue.split('-');

                if (selected[0] === REGION_FILTER) {
                    route.query.region = selected[1];

                    delete route.query.city;
                }

                if (selected[0] === CITY_FILTER) {
                    route.query.city = selected[1];

                    delete route.query.region;
                }

                localStorage.setItem(LOCAL_STORAGE_KEY, this.selectedValue);
            }

            // If homepage, redirect to main programme entry containing filters
            if (this.$route.name === 'index' && this.mainProgramEntry) {
                route.path = this.mainProgramEntry.uri;
            }

            this.$router.push(route);

            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }
};
