import { MODAL_MAIN } from '~/store/modal/constants';

// Prevent a route change when an event modal is opened.
export default function(context) {
    if (
        context.store.state.modal.activeModals[MODAL_MAIN] &&
        context.route.name === 'programma-contentSlug-id' &&
        context.store.state.events.activeEventId
    ) {
        context.next(false);
        // TODO: decide how to handle URLs for the event modal.
        // window.location.replace(`${window.location.origin}#${context.route.path}`);
    }
}
