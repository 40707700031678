export default {
    setFooterData(state, payload) {
        state.footer = payload;
    },

    setBecomeMemberCtaData(state, payload) {
        state.becomeMemberCtaLoginModal = payload.becomeMemberCtaLoginModal;
        state.becomeMemberCtaLoginModalSmallText = payload.becomeMemberCtaLoginModalSmallText;
        state.becomeMemberCtaDefault = payload.becomeMemberCtaDefault;
    }
};
