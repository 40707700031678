export default {
    data() {
        return {
            steps: [],
            currentStep: null,
            formData: {}
        };
    },

    computed: {
        currentStepNum() {
            return this.steps.indexOf(this.currentStep) + 1;
        },

        totalSteps() {
            return this.steps.length;
        }
    },

    methods: {
        setStep(step) {
            if (this.steps.includes(step)) {
                this.currentStep = step;
            }
        },

        nextStep(payload) {
            if (payload) {
                // Merge the payload of the form with our form data.
                this.formData = { ...this.formData, ...payload };
            }

            const index = this.steps.indexOf(this.currentStep);
            if (this.steps[index + 1]) {
                this.currentStep = this.steps[index + 1];
            }
        },

        previousStep() {
            const index = this.steps.indexOf(this.currentStep);
            this.hasError = false;

            if (this.steps[index - 1]) {
                this.currentStep = this.steps[index - 1];
            }
        }
    }
};
