

export default {
    props: {
        src: {
            type: String,
            default: '',
            required: true
        },

        srcset: {
            type: String,
            default: undefined,
            required: false
        },

        webpSrcset: {
            type: String,
            default: undefined,
            required: false
        },

        sizes: {
            type: String,
            default: undefined,
            required: false
        },

        srcTiny: {
            type: String,
            default: undefined
        },

        title: {
            type: String,
            default: ''
        },

        alt: {
            type: String,
            default() {
                return this.title;
            }
        },

        caption: {
            type: String,
            default: ''
        },

        width: {
            type: [String, Number],
            default: undefined,
            required: true
        },

        height: {
            type: [String, Number],
            default: undefined,
            required: true
        },

        credits: {
            type: String,
            default: null
        },

        hasFocalPoint: {
            type: Boolean,
            default: false
        },

        focalPoint: {
            type: Array,
            default: () => {}
        }
    },

    data() {
        return {
            loaded: false
        };
    },

    computed: {
        imgStyleAttrs() {
            if (this.hasFocalPoint && this.focalPoint) {
                return `object-position: ${this.focalPointAttr}`;
            }
            return '';
        },

        bgStyleAttrs() {
            if (this.hasFocalPoint && this.focalPoint) {
                return `background-position: ${this.focalPointAttr}`;
            }
            return '';
        },

        focalPointAttr() {
            if (this.hasFocalPoint && this.focalPoint) {
                return `${this.focalPoint[0] * 100}% ${this.focalPoint[1] * 100}%`;
            }
            return '';
        }
    }
};
