import { render, staticRenderFns } from "./EventReservationPanelWapres.vue?vue&type=template&id=505e57ee"
import script from "./EventReservationPanelWapres.vue?vue&type=script&lang=js"
export * from "./EventReservationPanelWapres.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {EventReservationButton: require('/builds/WeArePublic/wap-app/components/Event/ReservationButton/EventReservationButton.vue').default,BasePanel: require('/builds/WeArePublic/wap-app/components/Base/Panel/BasePanel.vue').default,EventWaitingListButton: require('/builds/WeArePublic/wap-app/components/Event/WaitingListButton/EventWaitingListButton.vue').default})
