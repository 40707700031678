import { render, staticRenderFns } from "./FormLogin.vue?vue&type=template&id=7532223c"
import script from "./FormLogin.vue?vue&type=script&lang=js"
export * from "./FormLogin.vue?vue&type=script&lang=js"
import style0 from "./FormLogin.less?vue&type=style&index=0&prod&lang=less&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseInput: require('/builds/WeArePublic/wap-app/components/Base/Input/BaseInput.vue').default,BaseFormField: require('/builds/WeArePublic/wap-app/components/Base/FormField/BaseFormField.vue').default,BaseButton: require('/builds/WeArePublic/wap-app/components/Base/Button/BaseButton.vue').default,BaseIcon: require('/builds/WeArePublic/wap-app/components/Base/Icon/BaseIcon.vue').default,BaseForm: require('/builds/WeArePublic/wap-app/components/Base/Form/Form.vue').default})
