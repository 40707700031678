export const CallToActionBlock = () => import('../../components/CallToActionBlock/CallToActionBlock.vue' /* webpackChunkName: "components/call-to-action-block" */).then(c => wrapFunctional(c.default || c))
export const CitiesSelect = () => import('../../components/CitiesSelect/CitiesSelect.vue' /* webpackChunkName: "components/cities-select" */).then(c => wrapFunctional(c.default || c))
export const CallToActionButton = () => import('../../components/CallToActionButton/CallToActionButton.vue' /* webpackChunkName: "components/call-to-action-button" */).then(c => wrapFunctional(c.default || c))
export const CookieBar = () => import('../../components/CookieBar/CookieBar.vue' /* webpackChunkName: "components/cookie-bar" */).then(c => wrapFunctional(c.default || c))
export const CopyShareInput = () => import('../../components/CopyShareInput/CopyShareInput.vue' /* webpackChunkName: "components/copy-share-input" */).then(c => wrapFunctional(c.default || c))
export const FlexibleSectionAccordion = () => import('../../components/FlexibleSection/Accordion.vue' /* webpackChunkName: "components/flexible-section-accordion" */).then(c => wrapFunctional(c.default || c))
export const FlexibleSectionCallToAction = () => import('../../components/FlexibleSection/CallToAction.vue' /* webpackChunkName: "components/flexible-section-call-to-action" */).then(c => wrapFunctional(c.default || c))
export const FlexibleSectionCollectionsBlock = () => import('../../components/FlexibleSection/CollectionsBlock.vue' /* webpackChunkName: "components/flexible-section-collections-block" */).then(c => wrapFunctional(c.default || c))
export const FlexibleSectionEventBlock = () => import('../../components/FlexibleSection/EventBlock.vue' /* webpackChunkName: "components/flexible-section-event-block" */).then(c => wrapFunctional(c.default || c))
export const FlexibleSectionFeaturedCollection = () => import('../../components/FlexibleSection/FeaturedCollection.vue' /* webpackChunkName: "components/flexible-section-featured-collection" */).then(c => wrapFunctional(c.default || c))
export const FlexibleSectionImage = () => import('../../components/FlexibleSection/Image.vue' /* webpackChunkName: "components/flexible-section-image" */).then(c => wrapFunctional(c.default || c))
export const FlexibleSectionImages = () => import('../../components/FlexibleSection/Images.vue' /* webpackChunkName: "components/flexible-section-images" */).then(c => wrapFunctional(c.default || c))
export const FlexibleSectionInviteAFriend = () => import('../../components/FlexibleSection/InviteAFriend.vue' /* webpackChunkName: "components/flexible-section-invite-a-friend" */).then(c => wrapFunctional(c.default || c))
export const FlexibleSectionOrderGift = () => import('../../components/FlexibleSection/OrderGift.vue' /* webpackChunkName: "components/flexible-section-order-gift" */).then(c => wrapFunctional(c.default || c))
export const FlexibleSectionQuote = () => import('../../components/FlexibleSection/Quote.vue' /* webpackChunkName: "components/flexible-section-quote" */).then(c => wrapFunctional(c.default || c))
export const FlexibleSectionRichText = () => import('../../components/FlexibleSection/RichText.vue' /* webpackChunkName: "components/flexible-section-rich-text" */).then(c => wrapFunctional(c.default || c))
export const FlexibleSectionSignupForm = () => import('../../components/FlexibleSection/SignupForm.vue' /* webpackChunkName: "components/flexible-section-signup-form" */).then(c => wrapFunctional(c.default || c))
export const FlexibleSectionTextImage = () => import('../../components/FlexibleSection/TextImage.vue' /* webpackChunkName: "components/flexible-section-text-image" */).then(c => wrapFunctional(c.default || c))
export const FlexibleSectionTextText = () => import('../../components/FlexibleSection/TextText.vue' /* webpackChunkName: "components/flexible-section-text-text" */).then(c => wrapFunctional(c.default || c))
export const FlexibleSectionTextVideo = () => import('../../components/FlexibleSection/TextVideo.vue' /* webpackChunkName: "components/flexible-section-text-video" */).then(c => wrapFunctional(c.default || c))
export const FlexibleSectionTitle = () => import('../../components/FlexibleSection/Title.vue' /* webpackChunkName: "components/flexible-section-title" */).then(c => wrapFunctional(c.default || c))
export const FlexibleSectionVideo = () => import('../../components/FlexibleSection/Video.vue' /* webpackChunkName: "components/flexible-section-video" */).then(c => wrapFunctional(c.default || c))
export const FlexibleSections = () => import('../../components/FlexibleSections/FlexibleSections.vue' /* webpackChunkName: "components/flexible-sections" */).then(c => wrapFunctional(c.default || c))
export const FormFieldColumns = () => import('../../components/FormFieldColumns/FormFieldColumns.vue' /* webpackChunkName: "components/form-field-columns" */).then(c => wrapFunctional(c.default || c))
export const IbanInput = () => import('../../components/IbanInput/IbanInput.vue' /* webpackChunkName: "components/iban-input" */).then(c => wrapFunctional(c.default || c))
export const NavLink = () => import('../../components/Nav/Link.vue' /* webpackChunkName: "components/nav-link" */).then(c => wrapFunctional(c.default || c))
export const NewsletterSignup = () => import('../../components/NewsletterSignup/NewsletterSignup.vue' /* webpackChunkName: "components/newsletter-signup" */).then(c => wrapFunctional(c.default || c))
export const NoResults = () => import('../../components/NoResults/NoResults.vue' /* webpackChunkName: "components/no-results" */).then(c => wrapFunctional(c.default || c))
export const PageTitle = () => import('../../components/PageTitle/PageTitle.vue' /* webpackChunkName: "components/page-title" */).then(c => wrapFunctional(c.default || c))
export const QueryEvent = () => import('../../components/Query/Event.vue' /* webpackChunkName: "components/query-event" */).then(c => wrapFunctional(c.default || c))
export const QueryEventBundle = () => import('../../components/Query/EventBundle.vue' /* webpackChunkName: "components/query-event-bundle" */).then(c => wrapFunctional(c.default || c))
export const QueryHome = () => import('../../components/Query/Home.vue' /* webpackChunkName: "components/query-home" */).then(c => wrapFunctional(c.default || c))
export const QueryPage = () => import('../../components/Query/Page.vue' /* webpackChunkName: "components/query-page" */).then(c => wrapFunctional(c.default || c))
export const QueryCollection = () => import('../../components/Query/QueryCollection.vue' /* webpackChunkName: "components/query-collection" */).then(c => wrapFunctional(c.default || c))
export const QueryCollections = () => import('../../components/Query/QueryCollections.vue' /* webpackChunkName: "components/query-collections" */).then(c => wrapFunctional(c.default || c))
export const QueryEventUser = () => import('../../components/Query/QueryEventUser.vue' /* webpackChunkName: "components/query-event-user" */).then(c => wrapFunctional(c.default || c))
export const QuickLinks = () => import('../../components/QuickLinks/QuickLinks.vue' /* webpackChunkName: "components/quick-links" */).then(c => wrapFunctional(c.default || c))
export const ReservedLabel = () => import('../../components/ReservedLabel/ReservedLabel.vue' /* webpackChunkName: "components/reserved-label" */).then(c => wrapFunctional(c.default || c))
export const RichText = () => import('../../components/RichText/RichText.vue' /* webpackChunkName: "components/rich-text" */).then(c => wrapFunctional(c.default || c))
export const SavedLabel = () => import('../../components/SavedLabel/SavedLabel.vue' /* webpackChunkName: "components/saved-label" */).then(c => wrapFunctional(c.default || c))
export const SocialButtons = () => import('../../components/SocialButtons/SocialButtons.vue' /* webpackChunkName: "components/social-buttons" */).then(c => wrapFunctional(c.default || c))
export const StateSwitch = () => import('../../components/StateSwitch/StateSwitch.vue' /* webpackChunkName: "components/state-switch" */).then(c => wrapFunctional(c.default || c))
export const TheEscapeHatch = () => import('../../components/TheEscapeHatch/TheEscapeHatch.vue' /* webpackChunkName: "components/the-escape-hatch" */).then(c => wrapFunctional(c.default || c))
export const TheFooter = () => import('../../components/TheFooter/TheFooter.vue' /* webpackChunkName: "components/the-footer" */).then(c => wrapFunctional(c.default || c))
export const TheHamburgerMenu = () => import('../../components/TheHamburgerMenu/TheHamburgerMenu.vue' /* webpackChunkName: "components/the-hamburger-menu" */).then(c => wrapFunctional(c.default || c))
export const TheLogin = () => import('../../components/TheLogin/TheLogin.vue' /* webpackChunkName: "components/the-login" */).then(c => wrapFunctional(c.default || c))
export const TheLogo = () => import('../../components/TheLogo/TheLogo.vue' /* webpackChunkName: "components/the-logo" */).then(c => wrapFunctional(c.default || c))
export const TheMenuOverlay = () => import('../../components/TheMenuOverlay/TheMenuOverlay.vue' /* webpackChunkName: "components/the-menu-overlay" */).then(c => wrapFunctional(c.default || c))
export const TheNavBar = () => import('../../components/TheNavBar/TheNavBar.vue' /* webpackChunkName: "components/the-nav-bar" */).then(c => wrapFunctional(c.default || c))
export const TheSearchButton = () => import('../../components/TheSearchButton/TheSearchButton.vue' /* webpackChunkName: "components/the-search-button" */).then(c => wrapFunctional(c.default || c))
export const TheSearchForm = () => import('../../components/TheSearchForm/TheSearchForm.vue' /* webpackChunkName: "components/the-search-form" */).then(c => wrapFunctional(c.default || c))
export const Toasts = () => import('../../components/Toasts/Toasts.vue' /* webpackChunkName: "components/toasts" */).then(c => wrapFunctional(c.default || c))
export const ToggleButton = () => import('../../components/ToggleButton/ToggleButton.vue' /* webpackChunkName: "components/toggle-button" */).then(c => wrapFunctional(c.default || c))
export const BaseAccordion = () => import('../../components/Base/Accordion/BaseAccordion.vue' /* webpackChunkName: "components/base-accordion" */).then(c => wrapFunctional(c.default || c))
export const BaseButton = () => import('../../components/Base/Button/BaseButton.vue' /* webpackChunkName: "components/base-button" */).then(c => wrapFunctional(c.default || c))
export const BaseCheckbox = () => import('../../components/Base/Checkbox/BaseCheckbox.vue' /* webpackChunkName: "components/base-checkbox" */).then(c => wrapFunctional(c.default || c))
export const BaseColumns = () => import('../../components/Base/Columns/BaseColumns.vue' /* webpackChunkName: "components/base-columns" */).then(c => wrapFunctional(c.default || c))
export const BaseFilterSelect = () => import('../../components/Base/FilterSelect/BaseFilterSelect.vue' /* webpackChunkName: "components/base-filter-select" */).then(c => wrapFunctional(c.default || c))
export const BaseFilters = () => import('../../components/Base/Filters/BaseFilters.vue' /* webpackChunkName: "components/base-filters" */).then(c => wrapFunctional(c.default || c))
export const BaseForm = () => import('../../components/Base/Form/Form.vue' /* webpackChunkName: "components/base-form" */).then(c => wrapFunctional(c.default || c))
export const BaseFormField = () => import('../../components/Base/FormField/BaseFormField.vue' /* webpackChunkName: "components/base-form-field" */).then(c => wrapFunctional(c.default || c))
export const BaseHero = () => import('../../components/Base/Hero/Hero.vue' /* webpackChunkName: "components/base-hero" */).then(c => wrapFunctional(c.default || c))
export const BaseIcon = () => import('../../components/Base/Icon/BaseIcon.vue' /* webpackChunkName: "components/base-icon" */).then(c => wrapFunctional(c.default || c))
export const BaseImage = () => import('../../components/Base/Image/BaseImage.vue' /* webpackChunkName: "components/base-image" */).then(c => wrapFunctional(c.default || c))
export const BaseInput = () => import('../../components/Base/Input/BaseInput.vue' /* webpackChunkName: "components/base-input" */).then(c => wrapFunctional(c.default || c))
export const BaseLabel = () => import('../../components/Base/Label/BaseLabel.vue' /* webpackChunkName: "components/base-label" */).then(c => wrapFunctional(c.default || c))
export const BaseLoader = () => import('../../components/Base/Loader/BaseLoader.vue' /* webpackChunkName: "components/base-loader" */).then(c => wrapFunctional(c.default || c))
export const BaseModal = () => import('../../components/Base/Modal/BaseModal.vue' /* webpackChunkName: "components/base-modal" */).then(c => wrapFunctional(c.default || c))
export const BasePaginator = () => import('../../components/Base/Paginator/BasePaginator.vue' /* webpackChunkName: "components/base-paginator" */).then(c => wrapFunctional(c.default || c))
export const BasePanel = () => import('../../components/Base/Panel/BasePanel.vue' /* webpackChunkName: "components/base-panel" */).then(c => wrapFunctional(c.default || c))
export const BaseRadio = () => import('../../components/Base/Radio/BaseRadio.vue' /* webpackChunkName: "components/base-radio" */).then(c => wrapFunctional(c.default || c))
export const BaseSection = () => import('../../components/Base/Section/BaseSection.vue' /* webpackChunkName: "components/base-section" */).then(c => wrapFunctional(c.default || c))
export const BaseSelect = () => import('../../components/Base/Select/BaseSelect.vue' /* webpackChunkName: "components/base-select" */).then(c => wrapFunctional(c.default || c))
export const BaseSheet = () => import('../../components/Base/Sheet/BaseSheet.vue' /* webpackChunkName: "components/base-sheet" */).then(c => wrapFunctional(c.default || c))
export const BaseSlider = () => import('../../components/Base/Slider/BaseSlider.vue' /* webpackChunkName: "components/base-slider" */).then(c => wrapFunctional(c.default || c))
export const BaseTestimonial = () => import('../../components/Base/Testimonial/BaseTestimonial.vue' /* webpackChunkName: "components/base-testimonial" */).then(c => wrapFunctional(c.default || c))
export const BaseTextarea = () => import('../../components/Base/Textarea/BaseTextarea.vue' /* webpackChunkName: "components/base-textarea" */).then(c => wrapFunctional(c.default || c))
export const BaseToast = () => import('../../components/Base/Toast/BaseToast.vue' /* webpackChunkName: "components/base-toast" */).then(c => wrapFunctional(c.default || c))
export const BaseVideoEmbed = () => import('../../components/Base/VideoEmbed/BaseVideoEmbed.vue' /* webpackChunkName: "components/base-video-embed" */).then(c => wrapFunctional(c.default || c))
export const CollectionCard = () => import('../../components/Collection/Card/CollectionCard.vue' /* webpackChunkName: "components/collection-card" */).then(c => wrapFunctional(c.default || c))
export const CollectionHero = () => import('../../components/Collection/Hero/CollectionHero.vue' /* webpackChunkName: "components/collection-hero" */).then(c => wrapFunctional(c.default || c))
export const CollectionSlider = () => import('../../components/Collection/Slider/CollectionSlider.vue' /* webpackChunkName: "components/collection-slider" */).then(c => wrapFunctional(c.default || c))
export const EventArtists = () => import('../../components/Event/Artists/EventArtists.vue' /* webpackChunkName: "components/event-artists" */).then(c => wrapFunctional(c.default || c))
export const EventCard = () => import('../../components/Event/Card/EventCard.vue' /* webpackChunkName: "components/event-card" */).then(c => wrapFunctional(c.default || c))
export const EventDate = () => import('../../components/Event/Date/EventDate.vue' /* webpackChunkName: "components/event-date" */).then(c => wrapFunctional(c.default || c))
export const EventDisciplines = () => import('../../components/Event/Disciplines/EventDisciplines.vue' /* webpackChunkName: "components/event-disciplines" */).then(c => wrapFunctional(c.default || c))
export const EventFilters = () => import('../../components/Event/Filter/EventFilters.vue' /* webpackChunkName: "components/event-filters" */).then(c => wrapFunctional(c.default || c))
export const EventGrid = () => import('../../components/Event/Grid/EventGrid.vue' /* webpackChunkName: "components/event-grid" */).then(c => wrapFunctional(c.default || c))
export const EventHero = () => import('../../components/Event/Hero/EventHero.vue' /* webpackChunkName: "components/event-hero" */).then(c => wrapFunctional(c.default || c))
export const EventList = () => import('../../components/Event/List/EventList.vue' /* webpackChunkName: "components/event-list" */).then(c => wrapFunctional(c.default || c))
export const EventMemberActions = () => import('../../components/Event/MemberActions/EventMemberActions.vue' /* webpackChunkName: "components/event-member-actions" */).then(c => wrapFunctional(c.default || c))
export const EventMeta = () => import('../../components/Event/Meta/EventMeta.vue' /* webpackChunkName: "components/event-meta" */).then(c => wrapFunctional(c.default || c))
export const EventModal = () => import('../../components/Event/Modal/EventModal.vue' /* webpackChunkName: "components/event-modal" */).then(c => wrapFunctional(c.default || c))
export const EventRelated = () => import('../../components/Event/Related/EventRelated.vue' /* webpackChunkName: "components/event-related" */).then(c => wrapFunctional(c.default || c))
export const EventReservationButton = () => import('../../components/Event/ReservationButton/EventReservationButton.vue' /* webpackChunkName: "components/event-reservation-button" */).then(c => wrapFunctional(c.default || c))
export const EventReservationPanelNores = () => import('../../components/Event/ReservationPanel/EventReservationPanelNores.vue' /* webpackChunkName: "components/event-reservation-panel-nores" */).then(c => wrapFunctional(c.default || c))
export const EventReservationPanelParres = () => import('../../components/Event/ReservationPanel/EventReservationPanelParres.vue' /* webpackChunkName: "components/event-reservation-panel-parres" */).then(c => wrapFunctional(c.default || c))
export const EventReservationPanelWapres = () => import('../../components/Event/ReservationPanel/EventReservationPanelWapres.vue' /* webpackChunkName: "components/event-reservation-panel-wapres" */).then(c => wrapFunctional(c.default || c))
export const EventReservationSheet = () => import('../../components/Event/ReservationSheet/EventReservationSheet.vue' /* webpackChunkName: "components/event-reservation-sheet" */).then(c => wrapFunctional(c.default || c))
export const EventSlider = () => import('../../components/Event/Slider/EventSlider.vue' /* webpackChunkName: "components/event-slider" */).then(c => wrapFunctional(c.default || c))
export const EventVenue = () => import('../../components/Event/Venue/EventVenue.vue' /* webpackChunkName: "components/event-venue" */).then(c => wrapFunctional(c.default || c))
export const EventWaitingListButton = () => import('../../components/Event/WaitingListButton/EventWaitingListButton.vue' /* webpackChunkName: "components/event-waiting-list-button" */).then(c => wrapFunctional(c.default || c))
export const FilterCitySelect = () => import('../../components/Filter/CitySelect/FilterCitySelect.vue' /* webpackChunkName: "components/filter-city-select" */).then(c => wrapFunctional(c.default || c))
export const FilterDateSelect = () => import('../../components/Filter/DateSelect/FilterDateSelect.vue' /* webpackChunkName: "components/filter-date-select" */).then(c => wrapFunctional(c.default || c))
export const FilterState = () => import('../../components/Filter/State/FilterState.vue' /* webpackChunkName: "components/filter-state" */).then(c => wrapFunctional(c.default || c))
export const FormAccountDetails = () => import('../../components/Form/AccountDetails/FormAccountDetails.vue' /* webpackChunkName: "components/form-account-details" */).then(c => wrapFunctional(c.default || c))
export const FormChangePassword = () => import('../../components/Form/ChangePassword/FormChangePassword.vue' /* webpackChunkName: "components/form-change-password" */).then(c => wrapFunctional(c.default || c))
export const FormForgotPassword = () => import('../../components/Form/ForgotPassword/FormForgotPassword.vue' /* webpackChunkName: "components/form-forgot-password" */).then(c => wrapFunctional(c.default || c))
export const FormInviteAFriend = () => import('../../components/Form/InviteAFriend/FormInviteAFriend.vue' /* webpackChunkName: "components/form-invite-a-friend" */).then(c => wrapFunctional(c.default || c))
export const FormLogin = () => import('../../components/Form/Login/FormLogin.vue' /* webpackChunkName: "components/form-login" */).then(c => wrapFunctional(c.default || c))
export const FormNotificationPreferences = () => import('../../components/Form/NotificationPreferences/FormNotificationPreferences.vue' /* webpackChunkName: "components/form-notification-preferences" */).then(c => wrapFunctional(c.default || c))
export const FormOrderGift = () => import('../../components/Form/OrderGift/FormOrderGift.vue' /* webpackChunkName: "components/form-order-gift" */).then(c => wrapFunctional(c.default || c))
export const FormPaymentInfo = () => import('../../components/Form/PaymentInfo/FormPaymentInfo.vue' /* webpackChunkName: "components/form-payment-info" */).then(c => wrapFunctional(c.default || c))
export const FormPersonalData = () => import('../../components/Form/PersonalData/FormPersonalData.vue' /* webpackChunkName: "components/form-personal-data" */).then(c => wrapFunctional(c.default || c))
export const FormResetPassword = () => import('../../components/Form/ResetPassword/FormResetPassword.vue' /* webpackChunkName: "components/form-reset-password" */).then(c => wrapFunctional(c.default || c))
export const FormSelectPreferences = () => import('../../components/Form/SelectPreferences/FormSelectPreferences.vue' /* webpackChunkName: "components/form-select-preferences" */).then(c => wrapFunctional(c.default || c))
export const FormSignup = () => import('../../components/Form/Signup/FormSignup.vue' /* webpackChunkName: "components/form-signup" */).then(c => wrapFunctional(c.default || c))
export const FormUserDetails = () => import('../../components/Form/UserDetails/FormUserDetails.vue' /* webpackChunkName: "components/form-user-details" */).then(c => wrapFunctional(c.default || c))
export const FormVerifyCode = () => import('../../components/Form/VerifyCode/FormVerifyCode.vue' /* webpackChunkName: "components/form-verify-code" */).then(c => wrapFunctional(c.default || c))
export const PageCollection = () => import('../../components/Page/Collection/PageCollection.vue' /* webpackChunkName: "components/page-collection" */).then(c => wrapFunctional(c.default || c))
export const PageError = () => import('../../components/Page/Error/PageError.vue' /* webpackChunkName: "components/page-error" */).then(c => wrapFunctional(c.default || c))
export const PageEvent = () => import('../../components/Page/Event/PageEvent.vue' /* webpackChunkName: "components/page-event" */).then(c => wrapFunctional(c.default || c))
export const PageProfile = () => import('../../components/Page/Profile/PageProfile.vue' /* webpackChunkName: "components/page-profile" */).then(c => wrapFunctional(c.default || c))
export const ShareButtonEmail = () => import('../../components/ShareButton/Email/ShareButtonEmail.vue' /* webpackChunkName: "components/share-button-email" */).then(c => wrapFunctional(c.default || c))
export const ShareButtonWhatsapp = () => import('../../components/ShareButton/Whatsapp/ShareButtonWhatsapp.vue' /* webpackChunkName: "components/share-button-whatsapp" */).then(c => wrapFunctional(c.default || c))
export const TemplateColumns = () => import('../../components/Template/Columns/TemplateColumns.vue' /* webpackChunkName: "components/template-columns" */).then(c => wrapFunctional(c.default || c))
export const TemplateContentPage = () => import('../../components/Template/ContentPage/TemplateContentPage.vue' /* webpackChunkName: "components/template-content-page" */).then(c => wrapFunctional(c.default || c))
export const TemplateDefault = () => import('../../components/Template/Default/TemplateDefault.vue' /* webpackChunkName: "components/template-default" */).then(c => wrapFunctional(c.default || c))
export const TemplatePanel = () => import('../../components/Template/Panel/TemplatePanel.vue' /* webpackChunkName: "components/template-panel" */).then(c => wrapFunctional(c.default || c))
export const UserBarcode = () => import('../../components/User/Barcode/UserBarcode.vue' /* webpackChunkName: "components/user-barcode" */).then(c => wrapFunctional(c.default || c))
export const UserCard = () => import('../../components/User/Card/UserCard.vue' /* webpackChunkName: "components/user-card" */).then(c => wrapFunctional(c.default || c))
export const UserCardSheet = () => import('../../components/User/CardSheet/UserCardSheet.vue' /* webpackChunkName: "components/user-card-sheet" */).then(c => wrapFunctional(c.default || c))
export const UserSubscription = () => import('../../components/User/Subscription/UserSubscription.vue' /* webpackChunkName: "components/user-subscription" */).then(c => wrapFunctional(c.default || c))
export const FormOrderGiftStepOne = () => import('../../components/Form/OrderGift/Step/One/FormOrderGiftStepOne.vue' /* webpackChunkName: "components/form-order-gift-step-one" */).then(c => wrapFunctional(c.default || c))
export const FormOrderGiftStepThree = () => import('../../components/Form/OrderGift/Step/Three/FormOrderGiftStepThree.vue' /* webpackChunkName: "components/form-order-gift-step-three" */).then(c => wrapFunctional(c.default || c))
export const FormOrderGiftStepTwo = () => import('../../components/Form/OrderGift/Step/Two/FormOrderGiftStepTwo.vue' /* webpackChunkName: "components/form-order-gift-step-two" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
