
export default {
    props: {
        event: {
            type: Object,
            default: null
        }
    },

    computed: {
        hasReservation() {
            return this.$store.getters['user/hasReservation'](this.event.id);
        },

        isInWaitingList() {
            return this.$store.getters['user/isInWaitingList'](this.event.id);
        },

        isFullyBooked() {
            return this.event.isFullyBooked;
        }
    }
};
