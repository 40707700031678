import { render, staticRenderFns } from "./default.vue?vue&type=template&id=1f7e7e15"
import script from "./default.vue?vue&type=script&lang=js"
export * from "./default.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TheNavBar: require('/builds/WeArePublic/wap-app/components/TheNavBar/TheNavBar.vue').default,TheMenuOverlay: require('/builds/WeArePublic/wap-app/components/TheMenuOverlay/TheMenuOverlay.vue').default,CookieBar: require('/builds/WeArePublic/wap-app/components/CookieBar/CookieBar.vue').default,TheFooter: require('/builds/WeArePublic/wap-app/components/TheFooter/TheFooter.vue').default,EventFilters: require('/builds/WeArePublic/wap-app/components/Event/Filter/EventFilters.vue').default,TheLogin: require('/builds/WeArePublic/wap-app/components/TheLogin/TheLogin.vue').default,BaseSheet: require('/builds/WeArePublic/wap-app/components/Base/Sheet/BaseSheet.vue').default,EventReservationSheet: require('/builds/WeArePublic/wap-app/components/Event/ReservationSheet/EventReservationSheet.vue').default,UserCardSheet: require('/builds/WeArePublic/wap-app/components/User/CardSheet/UserCardSheet.vue').default,PageEvent: require('/builds/WeArePublic/wap-app/components/Page/Event/PageEvent.vue').default,EventModal: require('/builds/WeArePublic/wap-app/components/Event/Modal/EventModal.vue').default,Toasts: require('/builds/WeArePublic/wap-app/components/Toasts/Toasts.vue').default})
