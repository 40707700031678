
export default {
    props: {
        value: {
            type: Array,
            default: null
        },

        toggleValue: {
            type: [String, Number],
            default: null
        }
    },

    computed: {
        isActive() {
            return this.value.includes(this.toggleValue);
        }
    },

    watch: {
        isActive() {
            this.$emit('toggle', this.isActive);
        }
    },

    methods: {
        onClick() {
            const newArray = [...this.value];

            // If currently active, remove from array
            if (this.isActive) {
                const index = newArray.indexOf(this.toggleValue);
                newArray.splice(index, 1);
            // If not active, add to array
            } else {
                newArray.push(this.toggleValue);
            }

            this.$emit('input', newArray);
        }
    }
};
