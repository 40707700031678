
import getCollectionsQuery from '~/graphql/queries/Collections.graphql';
import QueryMixin from '~/mixins/query.js';

export default {
    mixins: [
        QueryMixin
    ],

    props: {
        collectionTypeId: {
            type: [Number, String],
            default: null
        },

        fetchOnServer: {
            type: Boolean,
            default: true
        }
    },

    fetchOnServer() {
        return this.fetchOnServer;
    },

    fetchKey() {
        return `query-collections-${this.collectionTypeId}`;
    },

    async fetch() {
        const { data } = await this.craftQuery(
            getCollectionsQuery,
            {
                collectionTypeId: this.collectionTypeId
            }
        );

        await this.setEntryOrNotFound(data);
    },

    head() {
        return this.seo;
    }
};
