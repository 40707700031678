export default function resetEventModalScrollPosition() {
    const eventModal = document.querySelector('.event-modal');
    if (process.server || !eventModal) {
        return;
    }

    eventModal.scroll({
        top: 0,
        behavior: 'smooth'
    });
}
