export default {
    async fetchPreferences({ state, commit }) {
        // Don't refetch if prefences were already fetched
        if (state.preferencesFetched) {
            return;
        }

        const res = await this.$axios.get('/v2.0/preference-options');

        commit('setPreferences', res.data);
    }
};
