
import { applyOpacity, applyTransform } from '~/util/transforms';
import { SIGNUP_URL } from '~/constants/membership';
import { MODAL_LOGIN } from '~/store/modal/constants';

export default {
    data() {
        return {
            navBar: null,
            sheetName: MODAL_LOGIN,
            SIGNUP_URL
        };
    },

    computed: {
        becomeMemberText() {
            return this.$store.getters['navigation/becomeMemberCtaDefault'];
        }
    },

    mounted() {
        this.navBar = document.querySelector('.nav-bar');
        window.addEventListener('scroll', this.updateHeader);
        this.updateHeader();
    },

    methods: {
        updateHeader() {
            const offsetTop = window.pageYOffset;
            applyOpacity(this.navBar, offsetTop);
            applyTransform(this.navBar, 0, offsetTop, 0.75);
        }
    }
};
