import { render, staticRenderFns } from "./PageError.vue?vue&type=template&id=1e182508"
import script from "./PageError.vue?vue&type=script&lang=js"
export * from "./PageError.vue?vue&type=script&lang=js"
import style0 from "./PageError.less?vue&type=style&index=0&prod&lang=less&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseHero: require('/builds/WeArePublic/wap-app/components/Base/Hero/Hero.vue').default,BaseSection: require('/builds/WeArePublic/wap-app/components/Base/Section/BaseSection.vue').default,TemplateContentPage: require('/builds/WeArePublic/wap-app/components/Template/ContentPage/TemplateContentPage.vue').default})
