
export default {
    props: {
        state: {
            type: String,
            default: 'loading'
        },

        statusCode: {
            type: Number,
            default: 0
        }
    }
};
