
import { MODAL_LOGIN, MODAL_RESERVATION } from '~/store/modal/constants';
import { STATUS } from '~/constants/errorMessages';
import * as RESERVATION_TYPES from '~/constants/reservationTypes.js';

export default {
    props: {
        event: {
            type: Object,
            default: () => {}
        }
    },

    data() {
        return {
            loginModal: MODAL_LOGIN,
            savingFavorite: false
        };
    },

    computed: {
        isInWaitingList() {
            return this.$store.getters['user/isInWaitingList'](this.event.id);
        },

        favorited: {
            get() {
                return this.hasFavorite ? [this.event.id] : [];
            },
            async set(value) {
                if (!this.$auth.loggedIn) {
                    this.$store.dispatch('modal/open', { name: MODAL_LOGIN });
                    return;
                }

                this.savingFavorite = true;

                const method = value.length === 0 ? 'delete' : 'post';
                await this.$axios({
                    method,
                    url: `/v1.0/events/${this.event.id}/favorites`
                }).catch((error) => {
                    if (error?.response?.status === 500) {
                        this.errorMessage = STATUS['500'];
                    }
                    this.savingFavorite = false;
                });

                // Refresh the user, so we have the most recent favorites.
                this.$store.dispatch('events/updateMyListHome');

                this.$auth.fetchUser();
                this.savingFavorite = false;
            }
        },

        hasReservation() {
            return this.$store.getters['user/hasReservation'](this.event.id);
        },

        hasFavorite() {
            return this.$store.getters['user/hasFavorite'](this.event.id);
        },

        isWapresFullyBooked() {
            return this.event.reservationType === RESERVATION_TYPES.WAPRES && this.event.isFullyBooked && !this.event.doorsaleEnabled;
        },

        reservationState() {
            if (this.event.isCancelled) {
                return 'cancelled';
            }

            if (this.event.isSoldout) {
                return 'sold-out';
            }

            if (this.isWapresFullyBooked && !this.isInWaitingList) {
                return 'waiting-list';
            }

            if (this.isWapresFullyBooked) {
                return 'fully-booked';
            }

            if (!this.event.isActive) {
                return 'inactive';
            }

            if (!this.$auth.loggedIn) {
                return 'log-in';
            }

            return 'default';
        },

        reserveText() {
            if (this.reservationState === 'cancelled') {
                return 'Dit programma is afgelast';
            }

            if (this.reservationState === 'sold-out') {
                return 'Dit programma is uitverkocht';
            }

            if (this.reservationState === 'fully-booked') {
                return 'Volgereserveerd';
            }

            if (this.reservationState === 'inactive') {
                return 'Dit programma is al geweest';
            }

            if (this.reservationState === 'log-in') {
                return 'Log in om te reserveren';
            }

            return 'Tickets';
        },

        showButtonIcon() {
            if (this.reservationState !== 'default') {
                return false;
            }

            return true;
        },

        disabled() {
            const disabledStates = [
                'sold-out',
                'fully-booked',
                'inactive',
                'cancelled'
            ];

            return disabledStates.includes(this.reservationState);
        },
    },

    methods: {
        openReservationModal() {
            this.$store.dispatch('modal/open', {
                name: MODAL_RESERVATION,
                data: this.event
            });
        }
    }
};
