import { render, staticRenderFns } from "./EventHero.vue?vue&type=template&id=2936993a"
import script from "./EventHero.vue?vue&type=script&lang=js"
export * from "./EventHero.vue?vue&type=script&lang=js"
import style0 from "./EventHero.less?vue&type=style&index=0&prod&lang=less&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {EventDisciplines: require('/builds/WeArePublic/wap-app/components/Event/Disciplines/EventDisciplines.vue').default,BaseLabel: require('/builds/WeArePublic/wap-app/components/Base/Label/BaseLabel.vue').default,EventMeta: require('/builds/WeArePublic/wap-app/components/Event/Meta/EventMeta.vue').default,EventMemberActions: require('/builds/WeArePublic/wap-app/components/Event/MemberActions/EventMemberActions.vue').default,BaseHero: require('/builds/WeArePublic/wap-app/components/Base/Hero/Hero.vue').default})
