import { render, staticRenderFns } from "./EventBlock.vue?vue&type=template&id=124f7a13"
import script from "./EventBlock.vue?vue&type=script&lang=js"
export * from "./EventBlock.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NavLink: require('/builds/WeArePublic/wap-app/components/Nav/Link.vue').default,EventSlider: require('/builds/WeArePublic/wap-app/components/Event/Slider/EventSlider.vue').default,BaseButton: require('/builds/WeArePublic/wap-app/components/Base/Button/BaseButton.vue').default,EventList: require('/builds/WeArePublic/wap-app/components/Event/List/EventList.vue').default,EventGrid: require('/builds/WeArePublic/wap-app/components/Event/Grid/EventGrid.vue').default,BaseSection: require('/builds/WeArePublic/wap-app/components/Base/Section/BaseSection.vue').default,BaseLoader: require('/builds/WeArePublic/wap-app/components/Base/Loader/BaseLoader.vue').default,QueryEventBundle: require('/builds/WeArePublic/wap-app/components/Query/EventBundle.vue').default})
