import { render, staticRenderFns } from "./PageEvent.vue?vue&type=template&id=d7cb5d8a"
import script from "./PageEvent.vue?vue&type=script&lang=js"
export * from "./PageEvent.vue?vue&type=script&lang=js"
import style0 from "./PageEvent.less?vue&type=style&index=0&prod&lang=less&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {EventHero: require('/builds/WeArePublic/wap-app/components/Event/Hero/EventHero.vue').default,FlexibleSectionQuote: require('/builds/WeArePublic/wap-app/components/FlexibleSection/Quote.vue').default,FlexibleSectionRichText: require('/builds/WeArePublic/wap-app/components/FlexibleSection/RichText.vue').default,FlexibleSectionVideo: require('/builds/WeArePublic/wap-app/components/FlexibleSection/Video.vue').default,FlexibleSectionCallToAction: require('/builds/WeArePublic/wap-app/components/FlexibleSection/CallToAction.vue').default,EventRelated: require('/builds/WeArePublic/wap-app/components/Event/Related/EventRelated.vue').default,TemplatePanel: require('/builds/WeArePublic/wap-app/components/Template/Panel/TemplatePanel.vue').default,QueryEvent: require('/builds/WeArePublic/wap-app/components/Query/Event.vue').default})
