
import { nextTick } from 'vue';
import { mapGetters } from 'vuex';

import { MODAL_LOGIN, MODAL_MAIN } from '~/store/modal/constants';
import resetEventModalScrollPosition from '~/helpers/resetEventModalScrollPosition';
import favicons from '~/mixins/favicons';

export default {
    mixins: [favicons],

    data() {
        return {
            loginModal: MODAL_LOGIN,
            eventModal: MODAL_MAIN
        };
    },

    computed: {
        ...mapGetters({
            activeModals: 'modal/activeModals',
            activeEventId: 'events/activeEventId',
            activeEvent: 'events/activeEvent',
            showEventFilters: 'eventFilters/filtersVisible'
        }),

        isLoginOpen() {
            return this.activeModals[this.loginModal] === true;
        },

        isEventDetailOpen() {
            return this.activeModals[this.eventModal] === true;
        }
    },

    watch: {
        isEventDetailOpen(value) {
            if (value === true) {
                nextTick(this.resetEventModalScrollPosition);
            }
        }
    },

    methods: {
        resetEventModalScrollPosition,

        closeEvent() {
            this.$store.dispatch('events/deactivate');
            this.$store.dispatch('modal/close', { name: this.eventModal });
        }
    }
};
