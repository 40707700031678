export default {
    activateEvent(state, eventId) {
        state.activeEventId = eventId;
    },

    setActiveEventData(state, payload) {
        state.activeEvent = payload;
    },

    deactivateEvent(state) {
        state.activeEventId = null;
    },

    setStartPath(state, payload) {
        state.startPath = payload;
    },

    updateMyListHome(state) {
        ++state.myListHomeChanged;
    }
};
