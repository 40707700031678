export default {
    props: {
        sectionData: {
            type: Object,
            default: null
        },

        theme: {
            type: String,
            default: null
        }
    },

    computed: {
        colorTheme() {
            if (this.theme) {
                return this.theme;
            }

            if (!this.sectionData || !this.sectionData.colorTheme) {
                return;
            }

            return this.sectionData.colorTheme;
        }
    }
};
