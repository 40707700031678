
import { ValidationObserver } from 'vee-validate';

export default {
    components: { ValidationObserver },

    props: {
        panelClass: {
            type: String,
            default: ''
        }
    },

    methods: {
        submit() {
            this.$emit('submit');
        }
    }
};
