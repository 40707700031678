
export default {
    props: {
        width: {
            type: String,
            default: 'page',
            validator(value) {
                return ['content-sm', 'content-md', 'content-lg', 'content-xl', 'page', 'bleed'].includes(value);
            }
        },

        colorTheme: {
            type: String,
            default: 'dark',
            validator(value) {
                return ['dark', 'light'].includes(value);
            }
        }
    }
};
