import { render, staticRenderFns } from "./EventBundle.vue?vue&type=template&id=5dc529f9"
import script from "./EventBundle.vue?vue&type=script&lang=js"
export * from "./EventBundle.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NoResults: require('/builds/WeArePublic/wap-app/components/NoResults/NoResults.vue').default,StateSwitch: require('/builds/WeArePublic/wap-app/components/StateSwitch/StateSwitch.vue').default})
