import { set } from 'vue';

export default {
    activateModal(state, payload) {
        set(state.activeModals, payload.modalName, true);

        if (payload.data) {
            set(state.data, payload.modalName, payload.data);
        }

        document.body.setAttribute(`data-modal-${payload.modalName}-open`, 'true');
        document.body.setAttribute('data-prevent-scrolling', 'true');
    },

    deactivateModal(state, modalName) {
        set(state.activeModals, modalName, false);
        set(state.data, modalName, null);

        document.body.setAttribute(`data-modal-${modalName}-open`, 'false');

        const openModals = Object.values(state.activeModals).filter(value => value);
        if (openModals.length === 0) {
            document.body.removeAttribute('data-prevent-scrolling');
        }
    },

    deactivateAllModals(state) {
        set(state.activeModals, {});
    }
};
