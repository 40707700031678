import { executeGqlQuery } from '@digitalnatives/graphql-client';
import getFilterSettingsQuery from '~/graphql/queries/FilterSettings.graphql';

export default {
    async fetchFilterOptions({ state, commit }) {
        // Don't refetch if prefences were already fetched
        if (state.filterOptionsFetched) {
            return;
        }

        const { data } = await this.$laravelApi.get('/v2.0/events/filters');

        if (data) {
            commit('setFilterOptions', data);
        }
    },

    async fetchFilterSettings({ state, commit }) {
        // Don't refetch if settings were already fetched
        if (state.filterSettingsFetched) {
            return;
        }

        const { data } = await executeGqlQuery.call({ $axios: this.$craftApi }, getFilterSettingsQuery);

        if (data && data.globalSet) {
            commit('setFilterSettings', data.globalSet);
        }

        commit('setFilterSettingsFetched', true);
    }
};
