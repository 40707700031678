export default {
    user(state, user) {
        state.user = user;
    },

    token(state, token) {
        state.token = token;
    },

    email(state, email) {
        state.email = email;
    }
};
