
import FlexibleSectionMixin from '~/mixins/flexible-section';

export default {
    mixins: [
        FlexibleSectionMixin
    ],

    props: {
        image: {
            type: [Object, Array],
            default: null
        },
        textPlain: {
            type: String,
            default: null
        },
        author: {
            type: String,
            default: null
        },
        title: {
            type: String,
            default: null
        }
    },

    computed: {
        testimonialImage() {
            if (!this.image) {
                return null;
            }

            // Array image instead of object (by Craft assets field)
            if (Array.isArray(this.image)) {
                return this.image[0];
            }

            // Default: (most probably) an object
            return this.image;
        }
    }
};
