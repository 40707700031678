import Vue from 'vue';
import VueCookieConsent from '@digitalnatives/cookie-consent';

Vue.use(VueCookieConsent, {
    categories: [
        { id: 'all', required: true }
    ],

    reloadOnSave: true,
    expires: 365
});
