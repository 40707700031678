
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters({
            messages: 'toasts/messages'
        })
    },

    methods: {
        removeMessage(messageId) {
            this.$store.commit('toasts/removeMessage', messageId);
        }
    }
};
