
import { mapGetters } from 'vuex';

export default {
    props: {
        entry: {
            type: Object,
            required: true,
            default: null
        }
    },

    computed: {
        ...mapGetters({
            showEventFilters: 'eventFilters/filtersVisible'
        }),

        heroEvent() {
            if (!this.entry) {
                return null;
            }

            if (this.entry.heroEvent.length > 0) {
                return this.entry.heroEvent[0];
            }

            return null;
        },

        heroImage() {
            if (!this.entry) {
                return;
            }

            if (this.entry.heroImages && this.entry.heroImages.length > 0) {
                return this.entry.heroImages[0];
            }

            // Fall back on featured image
            // Note: at the moment pages aren't highlighted anywhere, so no featured image is used.
            // if (this.entry.featuredImages && this.entry.featuredImages.length > 0) {
            //     return this.entry.featuredImages[0];
            // }

            return false;
        }
    },

    mounted() {
        this.toggleEventFilters();
    },

    activated() {
        this.toggleEventFilters();
    },

    destroyed() {
        this.$store.commit('eventFilters/filtersVisible', false);
    },

    deactivated() {
        this.hideEventFilters();
    },

    methods: {
        toggleEventFilters() {
            if (this.entry && this.entry.__typename === 'page_programOverview_Entry') {
                this.$store.commit('eventFilters/filtersVisible', true);
            } else {
                this.hideEventFilters();
            }
        },

        hideEventFilters() {
            this.$store.commit('eventFilters/filtersVisible', false);
        }
    }
};
