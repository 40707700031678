import { executeGqlQuery } from '@digitalnatives/graphql-client';

export default {
    data() {
        return {
            entry: null,
            entries: null,
            seo: null,
            notFound: false,
            statusCode: null
        };
    },

    methods: {
        async setEntryOrNotFound(data) {
            if (data && data.entry) {
                this.entry = data.entry;
                this.seo = await this.$seo.getMetaData(data?.entry?.seo);
            } else if (data && data.entries) {
                this.entries = data.entries;
            } else {
                this.notFound = true;
                this.statusCode = 404;

                if (process.server) {
                    this.$nuxt.context.res.statusCode = 404;
                }
            }
        },

        async craftQuery(query, variables) {
            return await executeGqlQuery.call({ $axios: this.$craftApi }, query, variables);
        }
    },

    computed: {
        loadingState() {
            if (this.$fetchState?.error || this.notFound) {
                return 'error';
            }

            if (!this.$fetchState?.pending || this.entry) {
                return 'ready';
            }

            return 'loading';
        }
    }
};
