// Because nuxt will compile this plugin at build time, we reference internal functions as if they were imported externally.
import { executeGqlMultipartQuery, executeGqlQuery, stringifyGqlQuery } from '@digitalnatives/graphql-client';

export default (context, inject) => {
    // Inject $gql in Vue, Nuxt context and VueX store.
    inject('gql', {
        async executeQuery() { return await executeQueryWrapper(context, arguments); },
        async executeMultipartQuery() { return await executeGqlMultipartQuery.call(context, ...arguments); },
        stringifyQuery() { return stringifyGqlQuery.call(context, ...arguments); }
    });
};

async function executeQueryWrapper(context, args) {
    if (!args || !args.length) {
        throw new Error('Cannot call $gql.executeQuery without any arguments');
    }

    // If some callbacks were defined in the config, set them here as defaults.
    let newArgs =  [ ...args ];
    const moduleOptions = {"callbacks":{"headers":function(headers) {
        headers.Authorization = `Bearer ${this.$config.apiToken}`;

        return headers;
      },"variables":function(variables) {
        // Set a default site handle based on i18n-context if not explicitly passed to an individual query
        if (typeof variables.siteHandle === 'undefined') {
          variables.siteHandle = 'nl';
        }
        return variables;
      }}};

    if (moduleOptions.callbacks) {
        if (!newArgs[3]) {
            newArgs[3] = {};
        }

        if (!newArgs[3].error && moduleOptions.callbacks.error) {
            newArgs[3].error = moduleOptions.callbacks.error;
        }

        if (!newArgs[3].variables && moduleOptions.callbacks.variables) {
            newArgs[3].variables = moduleOptions.callbacks.variables;
        }

        if (!newArgs[3].headers && moduleOptions.callbacks.headers) {
            newArgs[3].headers = moduleOptions.callbacks.headers;
        }

        if (!newArgs[3].request && moduleOptions.callbacks.request) {
            newArgs[3].request = moduleOptions.callbacks.request;
        }

        if (!newArgs[3].results && moduleOptions.callbacks.results) {
            newArgs[3].results = moduleOptions.callbacks.results;
        }
    }

    return await executeGqlQuery.call(context, ...newArgs);
}
