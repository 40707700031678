
import FlexibleSectionMixin from '~/mixins/flexible-section';

export default {
    mixins: [
        FlexibleSectionMixin
    ],

    props: {
        richText: {
            type: String,
            default: null
        }
    }
};
