import { render, staticRenderFns } from "./EventReservationSheet.vue?vue&type=template&id=6bb94a80"
import script from "./EventReservationSheet.vue?vue&type=script&lang=js"
export * from "./EventReservationSheet.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {EventMeta: require('/builds/WeArePublic/wap-app/components/Event/Meta/EventMeta.vue').default,EventReservationPanelWapres: require('/builds/WeArePublic/wap-app/components/Event/ReservationPanel/EventReservationPanelWapres.vue').default,EventReservationPanelParres: require('/builds/WeArePublic/wap-app/components/Event/ReservationPanel/EventReservationPanelParres.vue').default,EventReservationPanelNores: require('/builds/WeArePublic/wap-app/components/Event/ReservationPanel/EventReservationPanelNores.vue').default,BaseSheet: require('/builds/WeArePublic/wap-app/components/Base/Sheet/BaseSheet.vue').default})
