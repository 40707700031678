import { render, staticRenderFns } from "./EventSlider.vue?vue&type=template&id=32ddff39"
import script from "./EventSlider.vue?vue&type=script&lang=js"
export * from "./EventSlider.vue?vue&type=script&lang=js"
import style0 from "./EventSlider.less?vue&type=style&index=0&prod&lang=less&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {EventCard: require('/builds/WeArePublic/wap-app/components/Event/Card/EventCard.vue').default,BaseSlider: require('/builds/WeArePublic/wap-app/components/Base/Slider/BaseSlider.vue').default})
