export default {
    addMessage(state, { title, message, status, duration, id }) {
        // Skip on duplicate ID
        if (id && state.messages.findIndex(toast => toast.id === id) !== -1) {
            return;
        }

        // Push to the list
        state.messages.push({
            title,
            message,
            status,
            duration,
            id: id || Math.floor(Math.random() * 100000) + 1 // Generate random ID
        });
    },

    removeMessage(state, messageId) {
        state.messages.splice(state.messages.findIndex(({ id }) => id === messageId), 1);
    }
};
