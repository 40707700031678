import { render, staticRenderFns } from "./TheSearchButton.vue?vue&type=template&id=263dc4b8"
import script from "./TheSearchButton.vue?vue&type=script&lang=js"
export * from "./TheSearchButton.vue?vue&type=script&lang=js"
import style0 from "./TheSearchButton.less?vue&type=style&index=0&prod&lang=less&external"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseIcon: require('/builds/WeArePublic/wap-app/components/Base/Icon/BaseIcon.vue').default,NavLink: require('/builds/WeArePublic/wap-app/components/Nav/Link.vue').default})
