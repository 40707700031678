
export default {
    props: {
        eventId: {
            type: [String, Number],
            required: true,
            default: null,
        }
    },

    data() {
        return {
            event: null,
            notFound: null,
            seo: null
        };
    },

    async fetch() {
        const response = await this.fetchEvent();

        if (response) {
            this.event = response.data;
            if (!process.server) {
                /* eslint-disable */
                setTimeout(function() {
                    document.querySelector('.event-modal')?.scroll({
                        top: 0,
                        behavior: 'smooth'
                    });
                }, 300);
                /* eslint-enable */
            }
        } else {
            this.notFound = true;

            if (process.server) {
                this.$nuxt.context.res.statusCode = 404;
            }
        }
    },

    head() {
        if (this.event) {
            return {
                title: this.event.title,
                meta: [
                    {
                        hid: 'description',
                        name: 'description',
                        content: this.seoDescription
                    },
                    {
                        hid: 'author',
                        name: 'author',
                        content: 'We Are Public'
                    },
                    {
                        hid: 'og:image',
                        property: 'og:image',
                        content: this.event?.featuredImage?.url_box_lg
                    },
                    {
                        hid: 'og:title',
                        property: 'og:title',
                        content: this.event.title
                    },
                    {
                        hid: 'og:description',
                        property: 'og:description',
                        content: this.seoDescription
                    },
                    {
                        hid: 'twitter:image',
                        name: 'twitter:image',
                        content: this.event?.featuredImage?.url_box_lg
                    }
                ]
            };
        }
    },

    computed: {
        seoDescription() {
            if (this.event) {
                return this.event?.description.replace(/<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g, '');
            }

            return null;
        },

        heroImage() {
            if (!this.event || !this.event.featuredImage) {
                return;
            }

            return this.event.featuredImage;
        },

        loadingState() {
            if (this.$fetchState.error || this.notFound) {
                return 'error';
            }

            if (!this.$fetchState.pending || this.event) {
                return 'ready';
            }

            return 'loading';
        }
    },

    watch: {
        eventId(to, from) {
            this.$fetch();
        }
    },

    methods: {
        async fetchEvent() {
            const date = new Date();
            const timestamp = Math.round(date.getTime() / 1000);
            const { data } = await this.$laravelApi({
                method: 'GET',
                url: `/v2.0/events/${this.eventId}?` + timestamp
            });
            this.$store.dispatch('events/setActiveEventData', data.data);
            return data;
        },
    }
};
