
export default {
    props: {
        query: {
            type: Object,
            default() {
                return {};
            }
        },

        fetchOnServer: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {
            events: null,
            totalResults: null,
            currentPageNumber: 1,
            lastPageNumber: null,
            notFound: false,
        };
    },

    fetchOnServer() {
        if (process.client) {
            return false;
        }

        if (process.server && this.fetchOnServer) {
            return true;
        }
    },

    fetchKey(getCounter) {
        return `${this.filterQuery}-[${getCounter('query-event-bundle')}]`;
    },

    async fetch() {
        this.currentPageNumber = 1;

        const data = await this.fetchEvents();

        if (data) {
            this.events = data.events;
            this.totalResults = data.meta.total;
            this.lastPageNumber = data.meta.lastPage;

            if (this.totalResults === 0) {
                this.$emit('hide');
            } else {
                this.$emit('show');
            }
        } else {
            this.notFound = true;
        }
    },

    computed: {
        startDateFilterState() {
            if (!this.$route.query.startDate) {
                return;
            }
            return this.$route.query.startDate;
        },

        cityFilterState() {
            if (!this.$route.query.city) {
                return;
            }

            return parseInt(this.$route.query.city, 10);
        },

        regionFilterState() {
            if (!this.$route.query.region) {
                return;
            }

            return parseInt(this.$route.query.region, 10);
        },

        loadingState() {
            if (this.$fetchState.error || this.notFound) {
                return 'error';
            }

            if (!this.$fetchState.pending || this.data) {
                return 'ready';
            }

            return 'loading';
        },

        filterQuery() {
            const filters = {};

            this.applyArtistFilter(filters);
            this.applyCityFilter(filters);
            this.applyRegionFilter(filters);
            this.applyCuratorFilter(filters);
            this.applyDisciplineFilter(filters);
            this.applyEventContentFilter(filters);
            this.applyPartnerFilter(filters);
            this.applyPresetFilter(filters);
            this.applyProgramLineFilter(filters);
            this.applyPurviewFilter(filters);
            this.applyTagFilter(filters);
            this.applyVenueFilter(filters);
            this.applyStartDateFilter(filters);
            this.applyExcludeFilter(filters);

            return JSON.stringify({
                q: filters,
                limit: this.query?.limit ?? 10
            });
        }
    },

    watch: {
        filterQuery() {
            this.$fetch();
        }
    },

    methods: {
        async fetchEvents() {
            const { data } = await this.$laravelApi({
                method: 'post',
                url: '/v2.0/events',
                params: {
                    page: this.currentPageNumber
                },
                data: this.filterQuery,
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            return data;
        },

        async loadMore() {
            ++this.currentPageNumber;

            const data = await this.fetchEvents();

            this.events.push.apply(this.events, data.events);
        },

        // Individual filter properties
        applyPresetFilter(filterObject) {
            if (this.query.preset) {
                filterObject.preset = this.query.preset;
            }
        },

        applyArtistFilter(filterObject) {
            this.addLookupFilter('artist', filterObject);
        },

        applyCityFilter(filterObject) {
            this.addLookupFilter('city', filterObject);

            if (!this.cityFilterState || filterObject.city.includes(this.cityFilterState)) {
                return;
            }

            filterObject.city.push(this.cityFilterState);
        },

        applyRegionFilter(filterObject) {
            this.addLookupFilter('region', filterObject);

            if (!this.regionFilterState || filterObject.region.includes(this.regionFilterState)) {
                return;
            }

            filterObject.region.push(this.regionFilterState);
        },

        applyCuratorFilter(filterObject) {
            this.addLookupFilter('curator', filterObject);
        },

        applyDisciplineFilter(filterObject) {
            this.addLookupFilter('discipline', filterObject);
        },

        applyEventContentFilter(filterObject) {
            this.addLookupFilter('eventContent', filterObject);
        },

        applyPartnerFilter(filterObject) {
            this.addLookupFilter('partner', filterObject);
        },

        applyProgramLineFilter(filterObject) {
            this.addLookupFilter('programLine', filterObject);
        },

        applyPurviewFilter(filterObject) {
            this.addLookupFilter('purview', filterObject);
        },

        applyTagFilter(filterObject) {
            this.addLookupFilter('tag', filterObject);
        },

        applyVenueFilter(filterObject) {
            this.addLookupFilter('venue', filterObject);
        },

        applyStartDateFilter(filterObject) {
            if (this.query.startDate) {
                filterObject.startDate = this.query.startDate;
            }

            if (!this.startDateFilterState) {
                return;
            }

            filterObject.startDate = this.startDateFilterState;
        },

        applyExcludeFilter(filterObject) {
            if (this.query.skipEvent) {
                filterObject.skipEvent = this.query.skipEvent;
            }
        },

        addLookupFilter(propertyName, filterObject) {
            let value = [];

            if (this.query[propertyName] && this.query[propertyName].length > 0) {
                value = this.query[propertyName].map(object => object.laravelModelId);
            }

            filterObject[propertyName] = value;
        }
    }
};
