export default {
    setPreferences(state, preferences) {
        if (state.preferencesFetched) {
            return;
        }

        state.cities = preferences.cities;
        state.preferencesFetched = true;
    }
};
