import { render, staticRenderFns } from "./TemplateContentPage.vue?vue&type=template&id=c57578a4"
import script from "./TemplateContentPage.vue?vue&type=script&lang=js"
export * from "./TemplateContentPage.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseHero: require('/builds/WeArePublic/wap-app/components/Base/Hero/Hero.vue').default,FilterState: require('/builds/WeArePublic/wap-app/components/Filter/State/FilterState.vue').default,FlexibleSections: require('/builds/WeArePublic/wap-app/components/FlexibleSections/FlexibleSections.vue').default})
