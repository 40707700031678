import { render, staticRenderFns } from "./NoResults.vue?vue&type=template&id=3f6ee209"
var script = {}
import style0 from "./NoResults.less?vue&type=style&index=0&prod&lang=less&external"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports