import { render, staticRenderFns } from "./FormResetPassword.vue?vue&type=template&id=32c53464"
import script from "./FormResetPassword.vue?vue&type=script&lang=js"
export * from "./FormResetPassword.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseInput: require('/builds/WeArePublic/wap-app/components/Base/Input/BaseInput.vue').default,BaseFormField: require('/builds/WeArePublic/wap-app/components/Base/FormField/BaseFormField.vue').default,BaseButton: require('/builds/WeArePublic/wap-app/components/Base/Button/BaseButton.vue').default,BaseForm: require('/builds/WeArePublic/wap-app/components/Base/Form/Form.vue').default})
