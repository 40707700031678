
import footerQuery from '~/graphql/queries/Footer.graphql';
import Navigation from '~/mixins/Navigation';
import QueryMixin from '~/mixins/query.js';

export default {
    mixins: [
        Navigation,
        QueryMixin
    ],

    data() {
        return {
            footerData: null
        };
    },

    async fetch() {
        const { data } = await this.craftQuery(footerQuery);

        if (data && data.globalSet) {
            this.footerData = data.globalSet;
        }
    }
};
