import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5f140a90 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _6d8521ed = () => interopDefault(import('../pages/zoeken.vue' /* webpackChunkName: "pages/zoeken" */))
const _1161b0f6 = () => interopDefault(import('../pages/profiel/abonnement.vue' /* webpackChunkName: "pages/profiel/abonnement" */))
const _e1bdd604 = () => interopDefault(import('../pages/profiel/beveiliging.vue' /* webpackChunkName: "pages/profiel/beveiliging" */))
const _c0c3e55e = () => interopDefault(import('../pages/profiel/gegevens.vue' /* webpackChunkName: "pages/profiel/gegevens" */))
const _73d7eaca = () => interopDefault(import('../pages/profiel/notificaties.vue' /* webpackChunkName: "pages/profiel/notificaties" */))
const _249f72bb = () => interopDefault(import('../pages/profiel/overzicht.vue' /* webpackChunkName: "pages/profiel/overzicht" */))
const _feb3f2f8 = () => interopDefault(import('../pages/profiel/reserveringen.vue' /* webpackChunkName: "pages/profiel/reserveringen" */))
const _0afb45dd = () => interopDefault(import('../pages/profiel/voorkeuren.vue' /* webpackChunkName: "pages/profiel/voorkeuren" */))
const _d527310e = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _4b2a1068 = () => interopDefault(import('../pages/collectie/_slug.vue' /* webpackChunkName: "pages/collectie/_slug" */))
const _b1c265a0 = () => interopDefault(import('../pages/programma/_contentSlug/_id.vue' /* webpackChunkName: "pages/programma/_contentSlug/_id" */))
const _5e78dff4 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/login",
    component: _5f140a90,
    name: "login"
  }, {
    path: "/zoeken",
    component: _6d8521ed,
    name: "zoeken"
  }, {
    path: "/profiel/abonnement",
    component: _1161b0f6,
    name: "profiel-abonnement"
  }, {
    path: "/profiel/beveiliging",
    component: _e1bdd604,
    name: "profiel-beveiliging"
  }, {
    path: "/profiel/gegevens",
    component: _c0c3e55e,
    name: "profiel-gegevens"
  }, {
    path: "/profiel/notificaties",
    component: _73d7eaca,
    name: "profiel-notificaties"
  }, {
    path: "/profiel/overzicht",
    component: _249f72bb,
    name: "profiel-overzicht"
  }, {
    path: "/profiel/reserveringen",
    component: _feb3f2f8,
    name: "profiel-reserveringen"
  }, {
    path: "/profiel/voorkeuren",
    component: _0afb45dd,
    name: "profiel-voorkeuren"
  }, {
    path: "/",
    component: _d527310e,
    name: "index"
  }, {
    path: "/collectie/:slug?",
    component: _4b2a1068,
    name: "collectie-slug"
  }, {
    path: "/programma/:contentSlug?/:id?",
    component: _b1c265a0,
    name: "programma-contentSlug-id"
  }, {
    path: "/*",
    component: _5e78dff4,
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
