
import HasSteps from '~/mixins/has-steps';
import { MODAL_LOGIN } from '~/store/modal/constants';

const STEP_LOGIN = 'login';
const STEP_FORGOT_PASSWORD = 'forgot-password';
const STEP_VERIFY = 'verify';
const STEP_NEW_PASSWORD = 'new-password';
const STEP_PASSWORD_CHANGED = 'password-changed';

export default {
    mixins: [HasSteps],

    data() {
        return {
            STEP_LOGIN,
            STEP_FORGOT_PASSWORD,
            STEP_VERIFY,
            STEP_NEW_PASSWORD,
            STEP_PASSWORD_CHANGED,
            steps: [STEP_LOGIN, STEP_FORGOT_PASSWORD, STEP_VERIFY, STEP_NEW_PASSWORD, STEP_PASSWORD_CHANGED],
            currentStep: STEP_LOGIN,
            formData: {}
        };
    },

    methods: {
        submitPassword() {
            this.currentStep = STEP_PASSWORD_CHANGED;
        },

        dismiss() {
            this.$store.dispatch('modal/close', { name: MODAL_LOGIN });
            this.$router.push('/');
        }
    }
};
