
import { STATUS } from '~/constants/errorMessages';

export default {
    props: {
        eventId: {
            type: [String, Number],
            default: null
        },

        eventTitle: {
            type: String,
            default: null
        },
        eventVenueName: {
            type: String,
            default: ''
        }
    },

    data() {
        return {
            isSubmitting: false,
            errorMessage: null
        };
    },

    computed: {
        isInWaitingList() {
            return this.$store.getters['user/isInWaitingList'](this.eventId);
        },

        joinWaitingListConfirmText() {
            if (this.eventTitle) {
                return 'Je meldt je aan voor de wachtlijst van ' +
                    this.eventTitle +
                    '. Dit is de online We Are Public wachtlijst en niet geldig aan de kassa van ' +
                    this.eventVenueName +
                    '.';
            }

            return 'Weet je zeker dat je je wilt aanmelden voor de wachtlijst?';
        },

        leaveWaitingListConfirmText() {
            if (this.eventTitle) {
                return `Weet je zeker dat je je wachtlijst aanmelding voor ${this.eventTitle} wilt annuleren?`;
            }

            return 'Weet je zeker dat je je wachtlijst aanmelding voor dit programma wilt annuleren?';
        }
    },

    methods: {
        toggleReservation() {
            if (this.isInWaitingList) {
                return this.leaveWaitingList();
            }

            return this.joinWaitingList();
        },

        async joinWaitingList() {
            const confirmed = confirm(this.joinWaitingListConfirmText);

            if (!confirmed) {
                return;
            }

            this.isSubmitting = true;

            await this.$axios({
                method: 'post',
                url: `/v2.0/user/me/waiting-list/${this.eventId}`
            }).catch((error) => {
                if (error) {
                    this.errorMessage = STATUS['500'];
                }
                this.isSubmitting = false;
            });

            this.isSubmitting = false;

            this.$store.dispatch('events/updateMyListHome');

            // Refresh the user, so we have the most recent reservations.
            this.$auth.fetchUser();

            this.$store.commit('toasts/addMessage', {
                title: 'Je hebt je aangemeld voor de wachtlijst',
                message: '',
                status: 'success'
            });
        },

        async leaveWaitingList() {
            const confirmed = confirm(this.leaveWaitingListConfirmText);

            if (!confirmed) {
                return;
            }

            this.isSubmitting = true;

            await this.$axios({
                method: 'delete',
                url: `/v2.0/user/me/waiting-list/${this.eventId}`
            }).catch(() => {
                this.errorMessage = STATUS['500'];
                this.isSubmitting = false;
            });

            // Refresh the user, so we have the most recent reservations.
            this.$auth.fetchUser();
            this.isSubmitting = false;

            this.$store.commit('toasts/addMessage', {
                title: 'Je hebt je afgemeld voor de wachtlijst',
                message: '',
                status: 'success'
            });

            this.$store.dispatch('events/updateMyListHome');
        }
    }
};
