
import { ValidationProvider } from 'vee-validate';

import {
    STATUS,
    PASSWORD_RESET_TOO_MANY_ATTEMPTS,
} from '~/constants/errorMessages';

export default {
    components: {
        ValidationProvider
    },

    props: {
        title: {
            type: String,
            default: null
        }
    },

    data() {
        return {
            formData: {
                email: undefined,
            },
            errorMessage: null
        };
    },

    methods: {
        async submitEmail() {
            this.errorMessage = null;

            await this.$axios({
                method: 'post',
                url: '/v2.0/user/email-confirmation/resend',
                data: {
                    email: this.formData.email
                }
            })
                .catch((error) => {
                    if (error.response.status === 429) {
                        this.errorMessage = PASSWORD_RESET_TOO_MANY_ATTEMPTS;
                    } else {
                        this.errorMessage = STATUS['500'];
                    }
                });

            if (this.errorMessage === null) {
                this.$store.dispatch('emailConfirm/setEmail', this.formData.email);

                this.$emit('emailSent');
            }
        }
    }
};
