
import Navigation from '~/mixins/Navigation';
import { applyTransform } from '~/util/transforms';

export default {
    mixins: [
        Navigation
    ],

    props: {
        title: {
            type: String,
            default: null
        },
        theme: {
            type: String,
            required: true,
            default: null
        },
        text: {
            type: String,
            default: null
        },
        image: {
            type: Object,
            default: () => {}
        },
        callToAction: {
            type: Object,
            default: () => {}
        },
        secondaryCallToAction: {
            type: Object,
            default: () => {}
        }
    },

    computed: {
        imageSrc() {
            if (this.image) {
                return this.image.w768;
            }
            return '';
        },

        imageSrcSet() {
            return `
                ${this.image.w320} 320w,
                ${this.image.w656} 656w,
                ${this.image.w1312} w1312w
            `;
        }
    },

    mounted() {
        this.img = this.$el.querySelector('.call-to-action__image .base-image');
        const scrollEl = this.$el.closest('.dn-modal') ? this.$el.closest('.dn-modal') : window;

        if (this.img) {
            scrollEl.addEventListener('scroll', this.updateImage);
            this.updateImage();
        }
    },

    methods: {
        updateImage() {
            const ctaBounding = this.$el.getBoundingClientRect();
            const offsetTop = ctaBounding.top - window.innerHeight - (this.img.offsetHeight * 0.5);
            applyTransform(this.img, '0', -offsetTop, 0.1);
        }
    }
};
