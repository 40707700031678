export default {
    setUser({ commit }, payload) {
        commit('user', payload);
    },

    setEmail({ commit }, payload) {
        commit('email', payload);
    },

    setToken({ commit }, payload) {
        commit('token', payload);
    }
};
