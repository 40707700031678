import dateOptions from '~/constants/dateOptions';

export default {
    filtersVisible: state => state.filtersVisible,
    cityOptions: (state) => {
        if (!state.filterOptions || !state.filterOptions.city) {
            return [];
        }

        const optionsClone = [...state.filterOptions.city];

        // Sort alphabetically
        optionsClone.sort((a, b) => {
            const labelA = a.label.toUpperCase();
            const labelB = b.label.toUpperCase();

            return (labelA < labelB) ? -1 : (labelA > labelB) ? 1 : 0;
        });

        return optionsClone;
    },

    regionOptions: (state) => {
        if (!state.filterOptions || !state.filterOptions.region) {
            return [];
        }

        return state.filterOptions.region;
    },

    dateOptions: () => dateOptions,
    mainProgramEntry: state => state.mainProgramEntry
};
