
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters({
            startPath: 'events/startPath'
        })
    },

    methods: {
        closeModal() {
            document.querySelector('.event-modal').scroll({
                top: 0
            });
            window.history.pushState('', '', this.$store.state.events.startPath);
            this.$emit('close');
        }
    },
};
