
import { RENDERCONDITION_LOGGED_IN, RENDERCONDITION_GUESTS } from '~/constants/renderConditions';
export default {
    props: {
        sections: {
            type: Array,
            required: true,
            default: () => { return []; }
        }
    },

    data() {
        return {
            map: {
                flexibleSections_accordion_BlockType: 'FlexibleSectionAccordion',
                flexibleSections_richText_BlockType: 'FlexibleSectionRichText',
                flexibleSections_image_BlockType: 'FlexibleSectionImage',
                flexibleSections_images_BlockType: 'FlexibleSectionImages',
                flexibleSections_video_BlockType: 'FlexibleSectionVideo',
                flexibleSections_quote_BlockType: 'FlexibleSectionQuote',
                flexibleSections_callToAction_BlockType: 'FlexibleSectionCallToAction',
                flexibleSections_textImage_BlockType: 'FlexibleSectionTextImage',
                flexibleSections_textText_BlockType: 'FlexibleSectionTextText',
                flexibleSections_textVideo_BlockType: 'FlexibleSectionTextVideo',
                flexibleSections_signup_BlockType: 'FlexibleSectionSignupForm',
                flexibleSections_inviteAFriend_BlockType: 'FlexibleSectionInviteAFriend',
                flexibleSections_orderGift_BlockType: 'FlexibleSectionOrderGift',
                flexibleSections_eventBlock_BlockType: 'FlexibleSectionEventBlock',
                flexibleSections_collectionsBlock_BlockType: 'FlexibleSectionCollectionsBlock',
                flexibleSections_featuredCollection_BlockType: 'FlexibleSectionFeaturedCollection',
            }
        };
    },

    computed: {
        filteredSections() {
            return this.sections.filter(section => this.shouldRender(section));
        }
    },

    methods: {
        shouldRender(section) {
            if (!section) {
                return false;
            }

            if (!section.__typename || !this.map[section.__typename]) {
                return false;
            }

            if (!section || !section.renderConditions || !section.renderConditions.length) {
                return false;
            }

            if (!section.renderConditions.includes(RENDERCONDITION_LOGGED_IN) && this.$auth.loggedIn) {
                return false;
            }

            if (!section.renderConditions.includes(RENDERCONDITION_GUESTS) && !this.$auth.loggedIn) {
                return false;
            }
            return true;
        }
    }
};
