
import { mapGetters } from 'vuex';
import { MODAL_RESERVATION } from '~/store/modal/constants';
import * as RESERVATION_TYPES from '~/constants/reservationTypes.js';

export default {
    data() {
        return {
            reservationModalName: MODAL_RESERVATION,
            reservationTypes: RESERVATION_TYPES
        };
    },

    computed: {
        ...mapGetters({
            activeModals: 'modal/activeModals',
            modalData: 'modal/data'
        }),

        event() {
            return this.modalData[this.reservationModalName];
        },

        reservationType() {
            if (!this.event) {
                return;
            }

            return this.event.reservationType;
        },

        hasReservation() {
            if (!this.event) {
                return;
            }

            return this.$store.getters['user/hasReservation'](this.event.id);
        },

        isFullyBooked() {
            return this.event.isFullyBooked;
        },

        isReservationSheetOpen() {
            return this.activeModals[this.reservationModalName] === true;
        },

        showPickUpInformationInFooter() {
            // Only show for WAPRES
            if (this.reservationType !== this.reservationTypes.WAPRES) {
                return false;
            }

            // Do show when a reservation is made
            if (this.hasReservation) {
                return true;
            }

            // Don't show when fully booked
            if (this.isFullyBooked) {
                return false;
            }

            // Else, just show it.
            return true;
        }
    }
};
