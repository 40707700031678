
import { ValidationProvider } from 'vee-validate';
import { LOGIN_INVALID_CREDENTIALS, LOGIN_INACTIVE_USER } from '~/constants/errorMessages';
import { SIGNUP_URL } from '~/constants/membership';
import { MODAL_LOGIN, MODAL_MAIN } from '~/store/modal/constants';

export default {
    components: {
        ValidationProvider
    },

    props: {
        title: {
            type: String,
            default: null
        }
    },

    data() {
        return {
            formData: {
                email: undefined,
                password: undefined
            },
            errorMessage: null,
            isSubmitting: false,
            SIGNUP_URL
        };
    },

    computed: {
        becomeMemberText() {
            return this.$store.getters['navigation/becomeMemberCtaLoginModal'];
        },
        becomeMemberSmallText() {
            return this.$store.getters['navigation/becomeMemberCtaLoginModalSmallText'];
        },
    },

    methods: {
        async userLogin() {
            this.isSubmitting = true;
            try {
                await this.$auth.loginWith('laravelJWT', { data: this.formData });
                this.closeLoginModal();
            } catch (err) {
                const responseMsg = err.response?.data?.message;

                if (responseMsg === 'invalid_credentials') {
                    this.errorMessage = LOGIN_INVALID_CREDENTIALS;
                } else if (responseMsg === 'membership_expired') {
                    this.errorMessage = LOGIN_INACTIVE_USER;
                } else {
                    this.errorMessage = null;
                }
            }
            this.isSubmitting = false;
        },

        closeLoginModal() {
            this.$store.dispatch('modal/close', { name: MODAL_LOGIN });
        },

        closeAllModals() {
            this.closeLoginModal();
            this.$store.dispatch('modal/close', { name: MODAL_MAIN });
        }
    }
};
