import { format, eachDayOfInterval } from 'date-fns';
import nlLocale from 'date-fns/locale/nl';
import { chunk } from 'lodash';
import capitalizeFirstLetter from '~/helpers/captitalizeFirstLetter';

const TODAY_DATE = new Date();
const THIS_WEEK = 'deze-week';
const THIS_WEEKEND = 'dit-weekend';
const NEXT_WEEK = 'volgende-week';
const NEXT_WEEKEND = 'volgend-weekend';

const RANGE_LABELS = {
    'deze-week': 'Deze week',
    'dit-weekend': 'Dit weekend',
    'volgende-week': 'Volgende week',
    'volgend-weekend': 'Volgend weekend'
};

function createDateOption(date, labelDateFormat = 'eeee d MMMM') {
    const label = formatDate(date, labelDateFormat);

    return {
        label: capitalizeFirstLetter(label),
        value: formatDate(date)
    };
}

function formatDate(date, dateFormat = 'dd-MM-yyyy') {
    return format(date, dateFormat, {
        locale: nlLocale
    });
}

export function getNextThreeWeeksOptions() {
    const today = TODAY_DATE;
    const dayOfWeekNumber = today.getDay() || 7; // Sunday is 0. Convert to 7 if that's the case.
    const daysTillNextMonday = 7 - dayOfWeekNumber + 1;
    const nextMonday = new Date().setDate(today.getDate() + daysTillNextMonday);
    const endOfRange = new Date().setDate(today.getDate() + daysTillNextMonday + 20);

    const threeWeekArray = eachDayOfInterval({
        start: nextMonday,
        end: endOfRange
    });

    const formattedOptions = threeWeekArray.map(date => createDateOption(date));

    return formattedOptions;
}

export function getNextThreeWeekGroups() {
    return chunk(getNextThreeWeeksOptions(), 7);
}

export function getRemainingDaysOfCurrentWeekOptions() {
    const today = TODAY_DATE;
    const dayOfWeekNumber = today.getDay() || 7; // Turn sunday into 7

    const daysLeftThisWeek = 7 - dayOfWeekNumber;

    const nextDay = new Date().setDate(today.getDate());
    const lastDay = new Date().setDate(today.getDate() + daysLeftThisWeek);

    const remainingDaysOfThisWeek = eachDayOfInterval({
        start: nextDay,
        end: lastDay
    });

    const formattedOptions = remainingDaysOfThisWeek.map(date => createDateOption(date));

    return formattedOptions;
}

export function getDateOptions() {
    const nextThreeWeeks = getNextThreeWeekGroups();
    const today = TODAY_DATE;
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    return [
        {
            label: 'Snelle keuze',
            options: [
                {
                    label: 'Vandaag',
                    value: formatDate(today)
                },
                {
                    label: 'Morgen',
                    value: formatDate(tomorrow)
                },
                {
                    label: RANGE_LABELS[THIS_WEEK],
                    value: THIS_WEEK
                },
                {
                    label: RANGE_LABELS[THIS_WEEKEND],
                    value: THIS_WEEKEND
                },
                {
                    label: RANGE_LABELS[NEXT_WEEK],
                    value: NEXT_WEEK
                },
                {
                    label: RANGE_LABELS[NEXT_WEEKEND],
                    value: NEXT_WEEKEND
                },
            ]
        },
        {
            label: 'Deze week',
            options: [
                ...getRemainingDaysOfCurrentWeekOptions()
            ]
        },
        {
            label: 'Volgende week',
            options: nextThreeWeeks[0]
        },

        {
            label: 'Over twee weken',
            options: nextThreeWeeks[1]
        }
    ];
}

const dateOptions = getDateOptions();

export {
    THIS_WEEK,
    THIS_WEEKEND,
    NEXT_WEEK,
    NEXT_WEEKEND,
    RANGE_LABELS
};

export default dateOptions;
