
export default {
    props: {
        image: {
            type: [Object, Boolean],
            default: null
        },

        heroEvent: {
            type: Object,
            default: null
        },

        heroLink: {
            type: Object,
            default: null
        }
    },

    computed: {
        partnerLogo() {
            if (this.heroEvent && this.heroEvent.heroPartnerLogo.length > 0) {
                return this.heroEvent.heroPartnerLogo[0];
            }

            return null;
        }
    }
};
