
export default {
    data() {
        return {
            selectedValue: this.$route.query.startDate || null
        };
    },

    computed: {
        dateOptions() {
            return this.$store.getters['eventFilters/dateOptions'];
        },

        mainProgramEntry() {
            return this.$store.getters['eventFilters/mainProgramEntry'];
        }
    },

    watch: {
        '$route.query.startDate'(value) {
            this.selectedValue = value;
        }
    },

    methods: {
        updateFilterState() {
            const route = {
                query: { ...this.$route.query }
            };

            if (!this.selectedValue) {
                delete route.query.startDate;
            } else {
                route.query.startDate = this.selectedValue;
            }

            // If homepage, redirect to main programme entry containing filters
            if (this.$route.name === 'index' && this.mainProgramEntry) {
                route.path = this.mainProgramEntry.uri;
            }

            this.$router.push(route);

            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }
};
