
export default {
    props: {
        value: {
            type: [String, Number],
            required: false,
            default: null
        },
        options: {
            type: Array,
            required: false,
            default: null
        },
        placeholder: {
            type: String,
            required: false,
            default: null
        },
        clearable: {
            type: Boolean,
            default: true
        }
    },

    methods: {
        clear() {
            this.$emit('input', '');
        }
    }
};
