
import { DnCookiebar } from '@digitalnatives/cookie-consent';
import { PRIVACY_URL } from '~/constants/membership';

export default {
    components: {
        DnCookiebar
    },

    data() {
        return {
            isOpen: true,
            PRIVACY_URL
        };
    },

    computed: {
        isCookiebarHidden() {
            if (!this.isCookieModalOpen) {
                return true;
            }

            return this.$cookieConsent.isCookiebarDismissed() && !this.isSaving;
        },

        isCookieModalOpen() {
            return this.isOpen;
        }
    }

};
