import { applyOpacity, applyTransform } from '~/util/transforms';
class MenuPlugin {
    constructor(Vue, $router) {
        this.$router = $router;

        const vm = this.initVm(Vue);
        this.bindRouterEvents();

        return {
            toggle: this.toggle.bind(this),
            close: this.close.bind(this),
            open: this.open.bind(this),
            get isOpen() {
                return vm.isOpen;
            }
        };
    }

    /**
     * Initialize Vue instance to make data binding possible
     * without having to provide a VueX store instance.
     *
     * @param   {Object}  Vue  Vue object as passed to the plugin
     *
     * @return  {void}
     */
    initVm(Vue) {
        this.menuVm = new Vue({
            data() {
                return {
                    isOpen: false
                };
            }
        });

        return this.menuVm;
    }

    /**
     * Close the menu after each route change
     *
     * @return  {void}
     */
    bindRouterEvents() {
        this.$router.afterEach((to) => {
            // Close the menu overlay
            this.close();
        });
    }

    close() {
        this.menuVm.isOpen = false;

        if (!process.server) {
            // Remove body data attributes, as the dn-modal is always present
            // for compatibility with static rendering.
            document.body.removeAttribute('data-modal-open');
            document.body.removeAttribute('data-prevent-scrolling');
        }
    }

    open() {
        this.menuVm.isOpen = true;

        if (!process.server) {
            // Add body data attributes, as the dn-modal is always present
            // for compatibility with static rendering.
            document.body.setAttribute('data-modal-open', 'true');
            document.body.setAttribute('data-prevent-scrolling', 'true');
        }
    }

    toggle() {
        this.menuVm.isOpen = !this.menuVm.isOpen;

        // Timeout required as the modal DOM element may not be present yet.
        setTimeout(function() {
            if (!process.server) {
                this.navBarElement = document.querySelector('.nav-bar');
                this.logoElement = document.querySelector('.nav-bar .logo');
                this.modalElement = document.querySelector('.dn-modal.menu-overlay');
            }

            if (this.modalElement && this.logoElement) {
                if (this.menuVm.isOpen) {
                    // Store the original styling when opening the menu
                    this.logoOpacity = this.logoElement.style.opacity;
                    this.logoTransform = this.logoElement.style.transform;

                    // Reset any styling on the nav bar
                    this.navBarElement.style.opacity = '1';
                    this.navBarElement.style.transform = 'none';

                    this.modalElement.addEventListener('scroll', this.updateLogo.bind(this));
                    this.updateLogo();
                } else {
                    this.modalElement.removeEventListener('scroll', this.updateLogo.bind(this));

                    // Reset styling to whatever it was while opening
                    this.logoElement.style.opacity = this.logoOpacity;
                    this.logoElement.style.transform = this.logoTransform;
                }
            }
        }.bind(this)
        , 100);
    }

    get isOpen() {
        return this.menuVm.isOpen;
    }

    updateLogo() {
        const offsetTop = this.modalElement.scrollTop;
        applyOpacity(this.logoElement, offsetTop);
        applyTransform(this.logoElement, offsetTop);
    }
}

export default {
    install(VueInstance, VueRouter) {
        VueInstance.prototype.$menu = VueInstance.$menu = new MenuPlugin(VueInstance, VueRouter);
    }
};
