export default {
    filtersVisible(state, filtersVisible) {
        state.filtersVisible = filtersVisible;
    },

    setFilterOptions(state, filterOptions) {
        if (state.filterOptionsFetched) {
            return;
        }

        state.filterOptions = filterOptions;
        state.filterOptionsFetched = true;
    },

    setFilterSettings(state, filterSettings) {
        if (filterSettings.mainProgramEntry && filterSettings.mainProgramEntry.length) {
            state.mainProgramEntry = filterSettings.mainProgramEntry[0];
        }
    },

    setFilterSettingsFetched(state, filterSettingsFetched) {
        state.filterSettingsFetched = filterSettingsFetched;
    }
};
